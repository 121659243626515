import { Option as IntentsifyOption } from "@intentsify/types";
import { useMemo } from "react";
import ReactSelect, { GroupBase, Props } from "react-select";
import { useSimpleSelectComponents } from "./useSimpleSelectComponents";
import { useSimpleSelectStyles } from "./useSimpleSelectStyles";

export type SimpleSelectProps<
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<
  Props<Option, IsMulti, Group>,
  "styles" | "components" | "onFetchDataChange" | "totalPages" | "currentPage"
> & { endReached?: () => void };

export const SimpleSelect = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  endReached,
  ...props
}: SimpleSelectProps<Option, IsMulti, Group>) => {
  const simpleSelectStyles = useSimpleSelectStyles<Option, IsMulti, Group>();
  const options = useMemo(() => ({ endReached }), [endReached]);
  const simpleSelectComponents = useSimpleSelectComponents<
    Option,
    IsMulti,
    Group
  >(options);

  return (
    <ReactSelect<Option, IsMulti, Group>
      {...props}
      styles={simpleSelectStyles}
      components={simpleSelectComponents}
    />
  );
};
