import type { CampaignDetails } from "@intentsify/types";
import { Flex } from "@chakra-ui/react";
import { AccountOpportunitySpotlightComponent } from "../AccountOpportunitySpotlight";
import { AccountConversionJourneySpotlightComponent } from "../AccountConversionJourneySpotlight";
import { useSpotlight } from "./Spotlight.hooks";

interface SpotlightComponentProps {
  campaignId: number;
  campaignDetails: CampaignDetails;
}

export const SpotlightComponent = ({
  campaignId,
  campaignDetails,
}: SpotlightComponentProps) => {
  const { data: spotlight, isFetching } = useSpotlight(
    campaignId,
    campaignDetails.domainId
  );

  return (
    <Flex gap={4} flexDirection="column" mb={2}>
      <AccountOpportunitySpotlightComponent
        isLoading={isFetching}
        accountOpportunity={spotlight?.accountOpportunity ?? null}
      />
      <AccountConversionJourneySpotlightComponent
        isLoading={isFetching}
        campaignId={campaignId}
        accountJourney={spotlight?.accountJourney ?? null}
      />
    </Flex>
  );
};
