import {
  Actions,
  EmptyCell,
  L_COLUMN_WIDTH,
  M_COLUMN_WIDTH,
  S_COLUMN_WIDTH,
  XS_COLUMN_WIDTH,
} from "components";
import { EditPixelScreenDefinition } from "screens";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import { CompanyPixelForList } from "@intentsify/types";

const ActionsCell = ({ pixelId }: { pixelId: number }) => {
  const navigate = useNavigate();
  const items = pixelId
    ? [
        {
          label: "Edit",
          icon: <FiEye />,
          onClick: () =>
            navigate(
              EditPixelScreenDefinition.navigate({
                pixelId: Number(pixelId),
              })
            ),
        },
      ]
    : [];
  return pixelId ? <Actions items={items} /> : <EmptyCell />;
};

const pageTrackingTableColumns = [
  {
    header: "ID",
    accessorKey: "pixelId",
    size: XS_COLUMN_WIDTH,
  },
  {
    header: "Page Identifier",
    accessorKey: "displayName",
    size: M_COLUMN_WIDTH,
  },
  {
    header: "Domains",
    accessorKey: "domains",
    enableSorting: false,
    size: L_COLUMN_WIDTH,
    cell: ({ row }: { row: Row<CompanyPixelForList> }) => {
      return row.original.domains.join(", ");
    },
  },
  {
    header: "Associated Intent Models",
    accessorKey: "campaignsIds",
    enableSorting: false,
    size: L_COLUMN_WIDTH,
    cell: ({ row }: { row: Row<CompanyPixelForList> }) => {
      return row.original.campaignsIds.join(", ");
    },
  },
  {
    header: "First Seen",
    accessorKey: "firstSeen",
    enableSorting: false,
    size: S_COLUMN_WIDTH,
    cell: ({ row }: { row: Row<CompanyPixelForList> }) => {
      return (
        <Text as="span">
          {row.original.firstSeen ? row.original.firstSeen : "N/A"}
        </Text>
      );
    },
  },
  {
    header: "Last Seen",
    accessorKey: "lastSeen",
    enableSorting: false,
    size: S_COLUMN_WIDTH,
    cell: ({ row }: { row: Row<CompanyPixelForList> }) => {
      return (
        <Text as="span">
          {row.original.lastSeen ? row.original.lastSeen : "N/A"}
        </Text>
      );
    },
  },
  {
    header: "Actions",
    cell: ({ row }: { row: Row<CompanyPixelForList> }) => {
      return <ActionsCell pixelId={row.original.pixelId} />;
    },
  },
];

export { pageTrackingTableColumns };
