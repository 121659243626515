import { TargetPersonaTopic } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { EmptyCell } from "components";
import { sortWithOtherLast } from "./utils";

export const columns: Array<ColumnDef<TargetPersonaTopic>> = [
  {
    header: "Persona Name",
    accessorKey: "personaName",
    sortingFn: (rowA, rowB) =>
      sortWithOtherLast(rowA.original.personaName, rowB.original.personaName),
  },

  {
    header: "Job Level",
    accessorKey: "jobLevel",
    cell: ({ row }) => {
      if (!row?.original?.jobLevel) {
        return <EmptyCell />;
      }

      return row?.original.jobLevel;
    },
  },
  {
    header: "Job Function",
    accessorKey: "jobFunction",
    cell: ({ row }) => {
      if (!row?.original?.jobFunction) {
        return <EmptyCell />;
      }

      return row?.original.jobFunction;
    },
  },
  {
    header: "Topic",
    accessorKey: "topic",
  },
  {
    header: "Score",
    accessorKey: "score",
  },
  {
    header: "Stage",
    accessorKey: "stage",
  },
];
