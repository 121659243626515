import { WebAnalyticsScreen } from "types";
import { createScreen } from "../../../../utils/createScreen";

const WebAnalyticsScreenDefinition = createScreen({
  name: WebAnalyticsScreen,
  path: "/website-visitor-tracking/website-visitor-analytics/:view",
  routePath: [
    "/website-visitor-analytics/",
    "/website-visitor-analytics/:view",
  ],
  accessPath: "company.websiteVisitorsAnalytics",
});

export { WebAnalyticsScreenDefinition };
