import { apiService, Endpoints } from "api";
import { CompanyPixelRO, MutateCompanyPixelDTO } from "@intentsify/dto";

const createCompanyPixel = async (
  companyId: number,
  params: MutateCompanyPixelDTO
): Promise<CompanyPixelRO> => {
  const { data } = await apiService.post(
    Endpoints.Companies.Post.CreateCompanyPixel(companyId),
    params
  );

  return data;
};

const editCompanyPixel = async (
  companyId: number,
  pixelId: number,
  params: MutateCompanyPixelDTO
): Promise<CompanyPixelRO> => {
  const { data } = await apiService.put(
    Endpoints.Companies.Put.EditCompanyPixel(companyId, pixelId),
    params
  );

  return data;
};

const deleteCompanyPixel = async (companyId: number, pixelId: number) => {
  await apiService.delete(
    Endpoints.Companies.Delete.CompanyPixel(companyId, pixelId)
  );
};

export { createCompanyPixel, editCompanyPixel, deleteCompanyPixel };
