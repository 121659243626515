import { useScreen } from "utils";
import { CreatePixelScreenDefinition } from "./CreatePixel.definiton";
import { ViewContainer } from "components";
import { Alert, Flex } from "@chakra-ui/react";
import { PixelForm, useCreateCompanyPixel } from "../components";
import { useUserAgencyCompany } from "../../../../queries/companies/useUserAgencyCompany";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const CreatePixel = () => {
  useScreen(CreatePixelScreenDefinition);
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;

  const { createCompanyPixel, isLoading } = useCreateCompanyPixel(
    Number(selectedCompanyId)
  );
  return (
    <ViewContainer>
      <Flex width="100%" flexWrap="wrap">
        <Flex width="100%">
          <Alert status="info" mb={4}>
            <InfoOutlineIcon mr={2} />
            Create your pixel name that will need to start with "intentsify-"
            before your naming structure, and include the URL that the script
            tag will be implemented on.
          </Alert>
        </Flex>
        {selectedCompanyId && (
          <Flex width="100%">
            <PixelForm
              companyId={Number(selectedCompanyId)}
              mode="create"
              isLoading={isLoading}
              onSubmit={createCompanyPixel}
            />
          </Flex>
        )}
      </Flex>
    </ViewContainer>
  );
};

export { CreatePixel };
