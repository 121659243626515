import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useDisclosure } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { BusinessEvent } from "@intentsify/types";
import { formatDate, isDefined } from "@intentsify/utils";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Button, IconButton, Modal } from "components";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

export const useColumns = (): Array<ColumnDef<BusinessEvent>> => {
  const hasAccessToBusinessEventsUrls = useHasAccessTo(
    "campaign.businessEventUrl"
  );

  const columns: Array<ColumnDef<BusinessEvent>> = [
    {
      header: "Category",
      accessorKey: "category",
      accessorFn: (row: BusinessEvent) => startCase(toLower(row.category)),
    },
    {
      header: "Summary",
      accessorKey: "summary",
    },
    {
      header: "Date Published",
      accessorKey: "datePublished",
      cell: ({ row }: { row: Row<BusinessEvent> }) => {
        return formatDate({
          date: new Date(row.original.datePublished),
        });
      },
    },
    hasAccessToBusinessEventsUrls
      ? {
          header: "Source URL",
          accessorKey: "sourceUrl",
          cell: ({ row }: { row: Row<BusinessEvent> }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { isOpen, onOpen, onClose } = useDisclosure();
            const { sourceUrl } = row.original;

            return (
              <>
                <IconButton
                  tooltip="Navigate to the source article"
                  onClick={onOpen}
                  icon={<ExternalLinkIcon mx="2px" />}
                />

                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  title={"You're leaving intentsify.io"}
                  subtitle="This is an external site. If you wish to continue, please click the link below."
                  body={
                    <Link color="teal" href={sourceUrl} isExternal>
                      {sourceUrl}
                    </Link>
                  }
                  primaryButton={
                    <Button variant="primary" onClick={onClose}>
                      Cancel
                    </Button>
                  }
                />
              </>
            );
          },
        }
      : undefined,
  ].filter(isDefined);

  return columns;
};
