export const sortWithOtherLast = (a: string, b: string) => {
  if (a === "Other" && b !== "Other") {
    return 1;
  }

  if (b === "Other" && a !== "Other") {
    return -1;
  }

  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};
