import { Center, Text } from "@chakra-ui/layout";
import { TargetPersonaKeyword } from "@intentsify/types";
import { Table } from "components";
import { useMemo } from "react";
import { useKeywords } from "../BuyingGroup.requests";
import { columns } from "./KeywordsTable.utils";

type KeywordsTableProps = {
  campaignId: number;
  accountId: number;
  selectedPersonas: string[];
};

const KeywordsTable = ({
  campaignId,
  accountId,
  selectedPersonas,
}: KeywordsTableProps) => {
  const { data: keywordsData = [] } = useKeywords({
    accountId,
    campaignId,
  });

  const filteredKeywords = useMemo(() => {
    if (selectedPersonas.length > 0) {
      return keywordsData.filter((keyword) =>
        selectedPersonas.includes(`${keyword.jobLevel}, ${keyword.jobFunction}`)
      );
    }
    return keywordsData;
  }, [keywordsData, selectedPersonas]);

  if (filteredKeywords.length === 0) {
    return (
      <Center p={8}>
        <Text fontWeight={"semibold"}>No keywords found</Text>
      </Center>
    );
  }

  return (
    <Table<TargetPersonaKeyword>
      stretch
      maxWidth="100%"
      columns={columns}
      data={filteredKeywords}
      defaultSort={[{ id: "personaName", desc: false }]}
      isScrollable={false}
      isControlledByAPI={false}
    />
  );
};

export { KeywordsTable };
