import { PageTrackingScreen } from "types";
import { createScreen } from "../../../../utils/createScreen";

const PageTrackingScreenDefinition = createScreen({
  name: PageTrackingScreen,
  path: "/website-visitor-tracking/pixel-configuration-and-tracking",
  routePath: ["/pixel-configuration-and-tracking"],
  accessPath: "company.pixelSetup",
});

export { PageTrackingScreenDefinition };
