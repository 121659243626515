import { Stack, Text } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Tooltip } from "components";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { useComponentColors } from "theme";
import { FetchDataParamsWithToken } from "types";
import { useDeepEffect } from "utils";
import { Select } from "../Select";

type FiltersAsyncProps<
  T extends { results: Option[]; nextPageToken?: string },
  M extends boolean,
  O = M extends false ? Option : Option[]
> = {
  params?: FilterAsyncFetchDataParams;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  placeholder?: string;
  defaultOptions: Option[];
  onFilterValuesChange: (values: O | undefined) => void;
  dataRequest: (...args: any[]) => Promise<T>;
  // List of additional dependencies that will trigger data re-fetch
  resetFilterRequestDependencies?: any[];
  // Use to make the component controlled
  currentValue?: O | undefined;
  isMulti: M;
  testId?: string;
  noDataTooltip?: string;
  showLabel?: boolean;
};

type FilterAsyncFetchDataParams = Omit<
  FetchDataParamsWithToken<keyof Option>,
  "order" | "order_by"
> & {
  pageSize?: number;
  showOnlyCurrentUserCompanyCampaigns?: boolean;
};

const defaultListPaginatedParams: FilterAsyncFetchDataParams = {
  pageToken: "",
  search: undefined,
  pageSize: 250,
};

const FilterAsyncTokenBased = <
  T extends { results: Option[]; nextPageToken?: string },
  M extends boolean,
  O = M extends false ? Option : Option[]
>({
  isDisabled,
  label,
  isLoading,
  placeholder,
  dataRequest,
  defaultOptions,
  onFilterValuesChange,
  resetFilterRequestDependencies,
  currentValue,
  params,
  isMulti,
  testId,
  noDataTooltip,
  showLabel = true,
}: FiltersAsyncProps<T, M, O>) => {
  const [inputValue, setInputValue] = useState("");
  const [hasData, setHasData] = useState<boolean | undefined>(undefined);
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [selectOptions, setSelectOptions] = useState<Option[] | undefined>(
    undefined
  );
  const componentColors = useComponentColors();

  const [selectedValues, setSelectedValues] = useState<O | null>(
    currentValue ?? null
  );

  const defaultParams = useMemo(() => {
    return { ...defaultListPaginatedParams, ...(params || {}) };
  }, [params]);

  useEffect(() => {
    setSelectedValues(currentValue ?? null);
  }, [currentValue, setSelectedValues]);

  const [fetchDataParams, setFetchDataParams] =
    useState<FilterAsyncFetchDataParams>(defaultParams);

  const { isFetching, data, isInitialLoading } = useQuery(
    [
      "filterAsyncList",
      label,
      debouncedInputValue,
      fetchDataParams,
      ...(resetFilterRequestDependencies || []),
    ],
    () => {
      return dataRequest({
        ...fetchDataParams,
        search: debouncedInputValue === "" ? undefined : debouncedInputValue,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 0,
      enabled: !isDisabled,
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        const { results } = data;

        if (fetchDataParams.pageToken === undefined) {
          setSelectOptions(results);
          return;
        }

        if (debouncedInputValue.length > 0) {
          if (!isPopulatedArray(results)) {
            return;
          }

          setSelectOptions(results);
          return;
        }

        if (isEqual(fetchDataParams, defaultParams)) {
          setSelectOptions(results);

          /**
           * We want to determine if there are any results available. If default params are used and there is no results,
           * input can be disabled. Typing into search input will not yield and results anyway.
           */
          if (!isPopulatedArray(results)) {
            setHasData(false);
          } else {
            setHasData(true);
          }
        } else {
          const currentValues = [...(selectOptions ?? [])];
          setSelectOptions([...currentValues, ...results]);
        }
      },
    }
  );

  useDebounce(
    () => {
      if (selectOptions?.length !== 0 && inputValue.length !== 0) {
        setSelectOptions([]);
      }

      setFetchDataParams(defaultParams);
      setDebouncedInputValue(inputValue);
    },
    500,
    [inputValue]
  );

  useDeepEffect(() => {
    setSelectOptions(undefined);
    setFetchDataParams(defaultParams);
  }, [defaultParams]);

  const handleChange = (option: O | null) => {
    setSelectedValues(option ?? null);
    onFilterValuesChange(option ?? undefined);
  };

  const handleMenuClose = () => {
    if (!data?.nextPageToken) {
      setSelectOptions(data?.results);
      setFetchDataParams(defaultParams);
    }
  };

  const handleOnFetchDataChange = () => {
    setFetchDataParams((prevState: FilterAsyncFetchDataParams) => {
      if (data?.nextPageToken) {
        return {
          ...prevState,
          pageToken: data.nextPageToken,
        };
      }

      return prevState;
    });
  };

  return (
    <Stack data-testid={testId}>
      {showLabel && label && (
        <Text
          p="0"
          m="0"
          fontSize="sm"
          fontWeight="md"
          color={componentColors.form.formLabelColor}
        >
          {label}:
        </Text>
      )}

      <Tooltip
        aria-label={label}
        label={!isLoading && !isFetching && !hasData && noDataTooltip}
        shouldWrapChildren
      >
        <Select
          inputValue={inputValue}
          isDisabled={(isInitialLoading && !hasData) || isDisabled}
          options={selectOptions ? selectOptions : defaultOptions}
          onInputChange={(value: string) => {
            setInputValue(value);
          }}
          filterOption={() => true} // disable native filter
          isLoading={isLoading || isFetching}
          isMulti={isMulti}
          placeholder={placeholder}
          // @ts-ignore
          value={selectedValues}
          onFetchDataChange={handleOnFetchDataChange}
          onMenuClose={handleMenuClose}
          // @ts-ignore
          onChange={(option) => handleChange(option)}
        />
      </Tooltip>
    </Stack>
  );
};

export { FilterAsyncTokenBased };
