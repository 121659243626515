import {
  Box,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { AccountJourney, AccountJourneyDynamicUrl } from "@intentsify/types";
import { formatDate, pluralize } from "@intentsify/utils";
import { Card } from "components";
import groupBy from "lodash/groupBy";
import { Fragment, useMemo } from "react";
import type { Colour } from "theme";
import {
  createGetColour,
  MarkerLineType,
  MarkerNamePlacement,
  TimeseriesNew,
  useGetColour,
  WEEK_TIME_GAP,
} from "../../../../../../../../../components/Charts/TimeseriesNew";
import {
  ChartExport,
  useExportableChart,
} from "../../../../../../../../../shared/components";

interface AccountConversionJourneySpotlightComponentProps {
  campaignId: number;
  isLoading: boolean;
  accountJourney: AccountJourney | null;
}

const sortSeriesBySignalImpact = (
  series: Array<{
    name: string;
    items: Array<{ date: string; score: number }>;
  }>,
  signals: Array<{ date: string }>
) => {
  const seriesRanks = new Map<
    { name: string; items: Array<{ date: string; score: number }> },
    number
  >();

  for (const signal of signals) {
    for (const singleSeries of series) {
      const prevDataPoint = singleSeries.items.findLast(
        ({ date }) => date < signal.date
      );
      const nextDataPoint = singleSeries.items.find(
        ({ date }) => date >= signal.date
      );

      if (!prevDataPoint || !nextDataPoint) {
        continue;
      }
      seriesRanks.set(
        singleSeries,
        (seriesRanks.get(singleSeries) ?? 0) +
          Math.abs(nextDataPoint.score - prevDataPoint.score)
      );
    }
  }

  if (!seriesRanks.size) {
    return series;
  }

  for (const singleSeries of series) {
    if (!seriesRanks.has(singleSeries)) {
      seriesRanks.set(singleSeries, 0);
    }
  }

  return [...seriesRanks.entries()]
    .sort((a, b) => b[1] - a[1])
    .map(([series]) => series);
};

const cleanGroupedItems = <T extends object>(
  groupedItems: { name: string; items: T[] }[]
) => groupedItems.filter(({ items }) => items.length > 1);

const mergeClicksAndLeads = (accountJourney: AccountJourney) => {
  return Object.entries(
    groupBy(
      [
        ...(accountJourney.clicks ?? []).map(({ date, score }) => ({
          name: "Click",
          date,
          description: (
            <Box>
              <Text>{score} clicks generated</Text>
            </Box>
          ),
        })),
        ...(accountJourney.leads ?? []).map(({ date, score, lead }) => {
          const leads = lead.split(";");
          return {
            name: "Lead",
            date,
            description: (
              <Box>
                <Text>
                  {score} {pluralize(score, "lead")} generated by{" "}
                  {leads.length === 1 ? leads[0] : ""}
                </Text>
                {leads.length > 1 && (
                  <UnorderedList>
                    {leads.map((leadRecord) => (
                      <ListItem key={leadRecord}>{leadRecord}</ListItem>
                    ))}
                  </UnorderedList>
                )}
              </Box>
            ),
          };
        }),
      ],
      "date"
    )
  )
    .sort(([aDate], [bDate]) => aDate.localeCompare(bDate))
    .map(([date, signals], index, records) => ({
      labels: [
        {
          name: signals.map(({ name }) => name).join("&"),
        },
      ],
      description: (
        <>
          {signals.map(({ description }, index) => (
            <Fragment key={index}>{description}</Fragment>
          ))}
        </>
      ),
      isoDate: new Date(date).toISOString(),
      isFinal: index === records.length - 1,
    }));
};

export const AccountConversionJourneySpotlightComponent = ({
  campaignId,
  isLoading,
  accountJourney,
}: AccountConversionJourneySpotlightComponentProps) => {
  const getColour = useGetColour();

  const chartData = useMemo(() => {
    if (!accountJourney) {
      return {
        leftAxisLines: [],
        rightAxisLines: [],
        signals: [],
        columnarData: [],
        customLegend: [],
      };
    }
    const getSeriesColor = createGetColour([]);

    const groupedKeywords = cleanGroupedItems(
      Object.entries(groupBy(accountJourney.keywords ?? [], "keyword")).map(
        ([keyword, keywords]) => ({
          name: keyword,
          items: keywords,
        })
      )
    );

    const mainSignals = accountJourney.leads?.length
      ? accountJourney.leads
      : accountJourney.clicks?.length
      ? accountJourney.clicks
      : [];

    const chartKeywords = sortSeriesBySignalImpact(
      groupedKeywords,
      mainSignals
    ).map(({ name: keyword, items: keywords }, index) => ({
      legend: {
        group: "Keywords",
        label: keyword,
        color: getSeriesColor(),
        muteColor: index > 0,
      },
      data: keywords.map(({ date, score }) => ({
        isoDate: new Date(date).toISOString(),
        value: score,
      })),
    }));
    const groupedTopics = cleanGroupedItems(
      Object.entries(groupBy(accountJourney.topics ?? [], "topic")).map(
        ([keyword, keywords]) => ({
          name: keyword,
          items: keywords,
        })
      )
    );
    const chartTopics = sortSeriesBySignalImpact(
      groupedTopics,
      mainSignals
    ).map(({ name: topic, items: topics }, index) => ({
      legend: {
        group: "Topics",
        label: topic,
        color: getSeriesColor(),
        muteColor: index > 0,
      },
      data: topics.map(({ date, score }) => ({
        isoDate: new Date(date).toISOString(),
        value: score,
      })),
    }));

    const signalsGroupedByDate = groupBy(
      [
        ...mergeClicksAndLeads(accountJourney).map(
          ({ labels, isoDate, description, isFinal }) => ({
            labels: labels.map((label) => ({
              ...label,
              placement: "top" as MarkerNamePlacement,
              lineType: (isFinal ? "solid" : "dotted") as MarkerLineType,
              color: getColour("green" as Colour)[0],
            })),
            isoDate,
            description,
            width: isFinal ? 2 : 1,
          })
        ),
        ...(accountJourney.dynamicUrls ?? []).map(
          ({ dynamicUrls, date }: AccountJourneyDynamicUrl) => ({
            labels: [
              {
                name: "D1",
                placement: "bottom" as MarkerNamePlacement,
                lineType: "dotted" as MarkerLineType,
                color: getColour("teal" as Colour)[0],
              },
            ],
            description:
              dynamicUrls.length === 1 ? (
                <Box>Top URL: {dynamicUrls[0]}</Box>
              ) : (
                <Box>
                  Top URLs:{" "}
                  <UnorderedList maxW={600}>
                    {dynamicUrls.map((url) => (
                      <ListItem key={url}>{url}</ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              ),
            isoDate: new Date(date).toISOString(),
            width: 1,
          })
        ),
      ],
      "isoDate"
    );
    const chartSignals = Object.values(signalsGroupedByDate).map(
      (signalPerDay) =>
        signalPerDay.length > 1
          ? signalPerDay.reduce((acc, curr) => ({
              ...curr,
              labels: [...acc.labels, ...curr.labels],
              description: (
                <Flex flexDirection="column" gap={2}>
                  {acc.description}
                  {curr.description}
                </Flex>
              ),
              width: acc.width ?? curr.width,
            }))
          : signalPerDay[0]
    );
    const leadsLegend = accountJourney.dynamicUrls?.length
      ? [
          {
            section: "Top URLs",
            items: accountJourney.dynamicUrls
              .sort(({ date: aDate }, { date: bDate }) =>
                aDate.localeCompare(bDate)
              )
              .flatMap(({ date, dynamicUrls }) => [
                {
                  type: "subheader" as const,
                  label: formatDate({ date: new Date(date) }),
                },
                ...dynamicUrls.map((url) => ({
                  label: (
                    <Link
                      href={url}
                      isExternal
                      rel="noopener noreferrer"
                      display="inline-block"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxW="100%"
                    >
                      {url}
                    </Link>
                  ),
                })),
              ]),
          },
        ]
      : [];

    return {
      customLegend: [...leadsLegend],
      signals: chartSignals,
      leftAxisLines: chartKeywords,
      rightAxisLines: chartTopics,
      columnarData: [
        ...(accountJourney.topics ?? []).map(({ date, score, topic }) => ({
          date,
          type: "topic",
          name: topic,
          score,
        })),
        ...(accountJourney.keywords ?? []).map(({ date, score, keyword }) => ({
          date,
          type: "keyword",
          name: keyword,
          score,
        })),
        ...(accountJourney.dynamicUrls ?? []).map(({ date, dynamicUrls }) => ({
          date,
          type: "dynamicUrl",
          name: dynamicUrls.join(", "),
          score: "",
        })),
        ...(accountJourney.leads ?? []).map(({ date, lead, score }) => ({
          date,
          type: "lead",
          name: lead,
          score,
        })),
        ...(accountJourney.clicks ?? []).map(({ date, score }) => ({
          date,
          type: "click",
          name: "Click",
          score,
        })),
      ],
    };
  }, [accountJourney, getColour]);

  const trendingTopicsChart = useExportableChart({
    title: "Engagement Journey",
    campaignId,
    chart: (
      <Flex h="400px">
        <TimeseriesNew
          stretch
          leftAxisLabel="Keywords Intent Score"
          leftAxisLines={chartData.leftAxisLines}
          rightAxisLabel="Topics Intent Score"
          rightAxisLines={chartData.rightAxisLines}
          markers={chartData.signals}
          xAxisTimeGap={WEEK_TIME_GAP}
          customLegend={chartData.customLegend}
          skipDecimals={true}
          margins={{ t: 80, r: 45, b: 70, l: 45 }}
          xAxisLabels={
            accountJourney?.buyerResearchStages.map(
              ({ buyerResearchStage, date }) => ({
                label: buyerResearchStage,
                isoDate: new Date(date).toISOString(),
              })
            ) ?? []
          }
          timesScalePadding={{ paddingInner: 0, paddingOuter: -0.5 }}
        />
      </Flex>
    ),
  });

  return (
    <>
      <Card
        isLoading={isLoading}
        title={trendingTopicsChart.title}
        hasData={Boolean(accountJourney)}
        actions={
          <ChartExport
            size="small"
            campaignId={campaignId}
            title={trendingTopicsChart.title}
            onExportPNG={trendingTopicsChart.downloadAsPng}
            data={chartData.columnarData}
          />
        }
      >
        {trendingTopicsChart.component}
        {trendingTopicsChart.exportComponent}
      </Card>
    </>
  );
};
