import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useComponentColors } from "theme";
import { WebAnalyticsAccountView, Option } from "@intentsify/types";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  defaultListPixelsParams,
  DeliveryRecipientFormState,
  listCampaigns,
} from "screens";
import { DayPicker, FilterAsyncTokenBased, Select } from "components";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { Nullable } from "types";

type Props = {
  companyId: number;
  pixelId: number;
  errors: FieldErrors<DeliveryRecipientFormState>;
  register: UseFormRegister<DeliveryRecipientFormState>;
  control: Control<DeliveryRecipientFormState>;
  recipient: DeliveryRecipientFormState & { campaignName?: Nullable<string> };
  setValue: UseFormSetValue<DeliveryRecipientFormState>;
};

const DeliveryRecipientForm = ({
  companyId,
  pixelId,
  errors,
  control,
  register,
  recipient,
  setValue,
}: Props) => {
  const componentColors = useComponentColors();
  const [selectedCampaign, setSelectedCampaign] = useState<
    Option<number> | undefined
  >(
    recipient.campaignId && recipient.campaignName
      ? {
          label: recipient.campaignName,
          value: recipient.campaignId,
        }
      : undefined
  );

  return (
    <Flex flexDirection="column" py={4}>
      <FormControl
        width={400}
        mb={2}
        isInvalid={!!errors?.email?.message}
        isRequired
      >
        <FormLabel color={componentColors.form.formLabelColor} fontSize="xs">
          Email
        </FormLabel>
        <Input
          {...register("email")}
          type="email"
          placeholder="Email"
          shadow="sm"
          size="sm"
          rounded="md"
        />
        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        width={150}
        mb={2}
        isInvalid={!!errors?.startDate?.message}
        isRequired
      >
        <FormLabel
          fontSize="xs"
          mb={2}
          color={componentColors.form.formLabelColor}
        >
          Start Date
        </FormLabel>
        <Controller
          control={control}
          name="startDate"
          render={({ field: { onChange } }) => {
            return (
              <DayPicker
                initialValue={recipient.startDate}
                onChange={onChange}
              />
            );
          }}
        />
        <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        width={150}
        mb={2}
        isInvalid={!!errors?.endDate?.message}
        isRequired
      >
        <FormLabel
          fontSize="xs"
          mb={2}
          color={componentColors.form.formLabelColor}
        >
          End Date
        </FormLabel>
        <Controller
          control={control}
          name="endDate"
          render={({ field: { onChange } }) => {
            return (
              <DayPicker initialValue={recipient.endDate} onChange={onChange} />
            );
          }}
        />
        <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
      </FormControl>
      <FormControl mb={2} isRequired>
        <FormLabel
          fontSize="xs"
          mb={2}
          color={componentColors.form.formLabelColor}
        >
          Account View
        </FormLabel>
        <Controller
          name="accountView"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <Select
                options={[
                  {
                    value: WebAnalyticsAccountView.IntentModel,
                    label: startCase(
                      camelCase(
                        WebAnalyticsAccountView.IntentModel.split("_").join(" ")
                      )
                    ),
                  },
                  {
                    value: WebAnalyticsAccountView.AllAccounts,
                    label: startCase(
                      camelCase(
                        WebAnalyticsAccountView.AllAccounts.split("_").join(" ")
                      )
                    ),
                  },
                ]}
                value={{
                  value: recipient.accountView,
                  label: startCase(
                    camelCase(recipient.accountView.split("_").join(" "))
                  ),
                }}
                onChange={(e) => {
                  if (e) {
                    onChange(e.value);
                    if (e.value === WebAnalyticsAccountView.AllAccounts) {
                      setValue("campaignId", null);
                      setSelectedCampaign(undefined);
                    }
                  }
                }}
                isMulti={false}
                isClearable={false}
              />
            );
          }}
        />
      </FormControl>
      <FormControl
        isRequired={
          recipient.accountView === WebAnalyticsAccountView.IntentModel
        }
        mb={2}
      >
        <FormLabel
          fontSize="xs"
          mb={2}
          color={componentColors.form.formLabelColor}
        >
          Intent Model
        </FormLabel>
        <Controller
          name="campaignId"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <FilterAsyncTokenBased
                label={"Intent Model"}
                defaultOptions={[]}
                onFilterValuesChange={(e) => {
                  if (e) {
                    onChange(e.value);
                    setSelectedCampaign(e);
                  }
                }}
                currentValue={selectedCampaign}
                dataRequest={listCampaigns(companyId, pixelId)}
                isMulti={false}
                showLabel={false}
                isDisabled={
                  recipient.accountView === WebAnalyticsAccountView.AllAccounts
                }
                params={{
                  ...defaultListPixelsParams,
                  pageSize: 50,
                }}
                resetFilterRequestDependencies={[companyId]}
              />
            );
          }}
        />
      </FormControl>
    </Flex>
  );
};
export { DeliveryRecipientForm };
