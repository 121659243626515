import { CompanyPixel, SortDirection } from "@intentsify/types";
import { FetchDataParamsWithToken } from "types";

export type PageTrackingFetchDataParams = FetchDataParamsWithToken<
  keyof CompanyPixel
>;

export const defaultListPaginatedPageTrackingParams: PageTrackingFetchDataParams =
  {
    pageToken: "",
    order_by: "pixelId",
    order: SortDirection.DESC,
  };
