import { useQuery } from "@tanstack/react-query";
import { getWebsiteVisits } from "../../Visualization.requests";
import { useMemo } from "react";
import { isPopulatedArray } from "@intentsify/utils";
import { WebsiteVisitsOverTime } from "@intentsify/types";
import { useExportableChart } from "../../../../../../../../shared/components";
import { TimeseriesNew } from "../../../../../../../../components/Charts/TimeseriesNew";
import { Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { FaHashtag } from "react-icons/fa";
import { Flex } from "@chakra-ui/react";
import { Props } from "../../../../WebAnalytics.types";

export const WebsiteVisits = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  accountsIds,
  visitedPages,
}: Props) => {
  const { data: data, isFetching: isFetching } = useQuery({
    queryKey: [
      "webAnalyticsWebsiteVisits",
      pixelId,
      accountView,
      campaignId,
      startDate,
      endDate,
      accountsIds,
      visitedPages,
    ],
    queryFn: () =>
      getWebsiteVisits(pixelId, {
        accountView,
        campaignId,
        startDate,
        endDate,
        accountsIds,
        visitedPages,
      }),
  });

  const timeseriesData = useMemo(() => {
    const timeseries = data?.data || [];

    if (!isPopulatedArray(timeseries)) {
      return null;
    }

    return {
      pageViews: timeseries
        .reduce((acc, curr) => {
          const index = acc.findIndex((el) => el.date === curr.date);

          if (index < 0) {
            acc.push(curr);
            return acc;
          }

          acc[index] = {
            weekNumber: acc[index].weekNumber,
            yearNumber: acc[index].yearNumber,
            date: acc[index].date,
            pageVisits: acc[index].pageVisits + curr.pageVisits,
            accounts: acc[index].accounts + curr.accounts,
            isNewCustomer: true, // doesn't matter here
          };

          return acc;
        }, [] as WebsiteVisitsOverTime[])
        .map((i) => ({
          isoDate: i.date,
          value: i.pageVisits,
        })),
      newAccounts: timeseries
        .filter((i) => i.isNewCustomer)
        .map((i) => ({
          isoDate: i.date,
          value: i.accounts ?? 0,
        })),
      returningAccounts: timeseries
        .filter((i) => !i.isNewCustomer)
        .map((i) => ({
          isoDate: i.date,
          value: i.accounts ?? 0,
        })),
    };
  }, [data?.data]);

  const chart = useExportableChart({
    title: "Page Views by Returning or New Visitors",
    campaignId: Number(123),
    chart: (
      <TimeseriesNew
        exportMode={true}
        stretch
        leftAxisLabel="Accounts"
        rightAxisLabel="Page Views"
        rightAxisLines={[
          {
            data: timeseriesData?.pageViews ?? [],
            legend: { label: "Page Views", color: "yellow" },
          },
        ]}
        leftAxisBars={[
          {
            data: timeseriesData?.returningAccounts ?? [],
            legend: {
              label: "Returning accounts",
              color: "green",
            },
          },
          {
            data: timeseriesData?.newAccounts ?? [],
            legend: {
              label: "New on site",
              color: "blue",
            },
          },
        ]}
        legendPosition="bottom"
        displayLinesInFrontOfBars={true}
      />
    ),
  });

  return (
    <Card
      isLoading={isFetching}
      title="Page Views by Returning or New Visitors"
      isExpandable
      hasData={isPopulatedArray(data?.data)}
      noDataIcon={FaHashtag}
      noDataMessage={`No data found for the selected period and filter values`}
    >
      {(isExpanded) => {
        return (
          <>
            {isExpanded ? (
              <Flex>
                <Flex width="100%" height={MIN_MODAL_CONTENT_HEIGHT}>
                  {chart.component}
                </Flex>
              </Flex>
            ) : (
              <Flex>
                <Flex w="100%">
                  {chart.component}
                  {chart.exportComponent}
                </Flex>
              </Flex>
            )}
          </>
        );
      }}
    </Card>
  );
};
