import {
  chakra,
  ColorMode,
  Flex,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { SiteEngagementLiftRO } from "@intentsify/dto";
import { isPopulatedArray, toNumberDisplayValue } from "@intentsify/utils";
import { EMPTY_CELL, NoData } from "components";
import { colors } from "theme";
import { List } from "./List";

type CardsPros = {
  data: SiteEngagementLiftRO["cards"];
};

const getValueColour = (value: number, colorMode: ColorMode) => {
  const green =
    colorMode === "light" ? colors.green["500"] : colors.green["300"];

  return value > 0 ? green : undefined;
};

const Cards = ({ data }: CardsPros) => {
  const { colorMode } = useColorMode();
  const priorData = data.find((i) => i.period === "Prior Campaign Start");
  const currentData = data.find((i) => i.period === "Campaign Period");

  const goldTextColor = useColorModeValue(
    colors.yellow["400"],
    colors.yellow["500"]
  );

  if (!isPopulatedArray(data)) {
    return <NoData stretch height="auto" />;
  }

  return (
    <Flex w="100%" gap={4}>
      <Flex w="50%">
        <List
          title="Prior to Campaign Start"
          items={[
            {
              label: "Average Site Engagement",
              value: priorData?.averageSiteEngagement ?? EMPTY_CELL,
              valueFormatter: (value) => toNumberDisplayValue(value),
            },
            {
              label: "Total Accounts",
              value: priorData?.totalAccounts ?? EMPTY_CELL,
              valueFormatter: (value) => toNumberDisplayValue(value),
            },
            {
              label: "Inactive Accounts",
              value: priorData?.stoppedAccounts ?? EMPTY_CELL,
              valueFormatter: (value) => toNumberDisplayValue(value),
            },
            { label: null, value: null },
            { label: null, value: null },
          ]}
        />
      </Flex>

      <Flex w="50%">
        <List
          title="Campaign Period"
          items={[
            {
              label: "Average Site Engagement",
              value: currentData?.averageSiteEngagement ?? EMPTY_CELL,
              valueFormatter: (value) => toNumberDisplayValue(value),
            },
            {
              label: "Total Accounts",
              value: currentData?.totalAccounts ?? EMPTY_CELL,
              valueFormatter: (value) => {
                return (
                  <chakra.span
                    color={
                      priorData
                        ? getValueColour(
                            value - priorData.newAccounts,
                            colorMode
                          )
                        : undefined
                    }
                  >
                    {toNumberDisplayValue(value)}
                  </chakra.span>
                );
              },
            },
            {
              label: "Engagement Change",
              value: currentData?.engagementChange ?? EMPTY_CELL,
              valueFormatter: (value) => {
                return (
                  <chakra.span
                    color={getValueColour(value * 100, colorMode)}
                  >{`${toNumberDisplayValue(value * 100)}%`}</chakra.span>
                );
              },
            },
            {
              label: "New Accounts",
              value: currentData?.newAccounts ?? EMPTY_CELL,
              valueFormatter: (value) => {
                return (
                  <chakra.span color={getValueColour(value, colorMode)}>
                    {toNumberDisplayValue(value)}
                  </chakra.span>
                );
              },
            },
            {
              label: "Engagement From New Accounts",
              value: currentData?.engagementFromNewAccounts ?? EMPTY_CELL,
              valueFormatter: (value) => {
                return (
                  <chakra.span
                    color={getValueColour(value * 100, colorMode)}
                  >{`${toNumberDisplayValue(value * 100)}%`}</chakra.span>
                );
              },
              valueTextColor: goldTextColor,
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};

export { Cards };
