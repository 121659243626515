import { apiService, Endpoints } from "api";
import { FetchDataParams, Account } from "@intentsify/types";
import { getPaginatedQuery } from "utils";
import {
  GetWebAnalyticsAccountsTopUrlsRO,
  WebAnalyticsAccountsTopUrlsDTO,
} from "@intentsify/dto";

export type PaginatedWebAnalyticsAccountsParams = FetchDataParams<
  keyof Account
>;

const listVisitingAccounts =
  (pixelId: number) => async (params: PaginatedWebAnalyticsAccountsParams) => {
    const query = getPaginatedQuery<Account>(
      Endpoints.WebAnalytics.Get.ListVisitingAccounts(pixelId),
      params
    );

    const { data } = await apiService.get(query);
    return data;
  };

const listAccountsTopUrls = async (
  pixelId: number,
  params: WebAnalyticsAccountsTopUrlsDTO
) => {
  const { data } = await apiService.get<GetWebAnalyticsAccountsTopUrlsRO>(
    Endpoints.WebAnalytics.Get.AccountsTopUrls(pixelId),
    { params }
  );

  return data;
};

export { listVisitingAccounts, listAccountsTopUrls };
