import { CampaignAdvancedSettings } from "@intentsify/types";
import { useRef } from "react";
import { useParams } from "react-router";
import { MutateCampaignAdvancedSettingsParams } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.requests";
import { Controls } from "../Controls";
import { SubmitTrigger } from "../SettingsStep/SettingsForm";
import { ActivationSettingsForm } from "./ActivationSettingsForm/ActivationSettingsForm";

type ActivationSettingsStepProps = {
  editing: boolean;
  id: number;
  campaignAdvancedSettings: CampaignAdvancedSettings;
  onSubmit: (params: MutateCampaignAdvancedSettingsParams) => void;
  companyId: number;
};

const ActivationSettingsStep = ({
  editing,
  id,
  campaignAdvancedSettings,
  onSubmit,
  companyId,
}: ActivationSettingsStepProps) => {
  const formRef = useRef<SubmitTrigger | undefined>();
  const { step } = useParams<"step">();
  const campaignId = Number(useParams<"campaignId">().campaignId);

  if (!step || !campaignId) {
    throw Error("404");
  }

  return (
    <>
      <ActivationSettingsForm
        ref={formRef}
        id={id}
        campaignAdvancedSettings={campaignAdvancedSettings}
        onSubmit={onSubmit}
        companyId={companyId}
      />

      <Controls
        onNextLabel="Finish"
        editing={editing}
        isDisabled={false}
        formRef={formRef}
        step={Number(step)}
        campaignId={Number(campaignId)}
      />
    </>
  );
};

export { ActivationSettingsStep };
