import { PageTrackingScreen } from "types";
import { createScreen } from "utils/createScreen";

const CreatePixelScreenDefinition = createScreen({
  name: PageTrackingScreen,
  path: "/website-visitor-tracking/pixel-configuration-and-tracking/create",
  routePath: ["/pixel-configuration-and-tracking/create"],
  accessPath: "company.pixelSetup",
});

export { CreatePixelScreenDefinition };
