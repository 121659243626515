import { Route, Routes } from "react-router";
import { ProgrammaticDomainsRouter } from "./routes";
import {
  DigitalForecasting,
  DigitalForecastingScreenDefinition,
  ProgrammaticDomainsRootDefinition,
} from "screens";

const MediaActivationRouter = () => {
  return (
    <Routes>
      {ProgrammaticDomainsRootDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<ProgrammaticDomainsRouter />} />
      ))}

      {DigitalForecastingScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<DigitalForecasting />} />
      ))}
    </Routes>
  );
};

export { MediaActivationRouter };
