import { PageTrackingScreen } from "types";
import { createScreen } from "utils/createScreen";

const EditPixelScreenDefinition = createScreen({
  name: PageTrackingScreen,
  path: "/website-visitor-tracking/pixel-configuration-and-tracking/:pixelId",
  routePath: ["/pixel-configuration-and-tracking/:pixelId"],
  accessPath: "company.pixelSetup",
});

export { EditPixelScreenDefinition };
