import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { NoData } from "components";
import { SelectCampaignIcon } from "./icons";

type NoEntitySelectedProps = {
  entityName: string;
  hasData?: boolean;
  noDataMessage?: string;
};

export const NoEntitySelected = ({
  entityName,
  hasData = true,
  noDataMessage,
}: NoEntitySelectedProps) => {
  const noDataColor = useColorModeValue("brand.100", "gray.100");

  return (
    <NoData message="">
      <Flex width="375px" justifyContent="space-between" alignItems="center">
        <Icon
          as={SelectCampaignIcon}
          width={82}
          height={85}
          fill={noDataColor}
        />
        {hasData && (
          <Text
            width="270px"
            fontSize="20px"
            fontWeight={700}
            color={noDataColor}
          >
            Please select a {entityName} from the dropdown above.
          </Text>
        )}
        {!hasData && noDataMessage && (
          <Text
            width="270px"
            fontSize="20px"
            fontWeight={700}
            color={noDataColor}
          >
            {noDataMessage}
          </Text>
        )}
      </Flex>
    </NoData>
  );
};
