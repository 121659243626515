import { PageTrackingScreen } from "types";
import { createScreen } from "utils/createScreen";

const DeliveryRecipientScreenDefinition = createScreen({
  name: PageTrackingScreen,
  path: "/website-visitor-tracking/pixel-configuration-and-tracking/:pixelId/recipients/:recipientId",
  routePath: [
    "/pixel-configuration-and-tracking/:pixelId/recipients/:recipientId",
  ],
  accessPath: "company.pixelSetup",
});

export { DeliveryRecipientScreenDefinition };
