import { apiService, Endpoints } from "api";
import {
  ListCompanyPixelCampaignsRO,
  ListCompanyPixelsRO,
} from "@intentsify/dto";
import {
  CampaignForList,
  CompanyPixel,
  Item,
  SortDirection,
  WebAnalyticsAccountView,
} from "@intentsify/types";
import { getPaginatedQuery } from "utils";
import { FetchDataParamsWithToken } from "types";

const listCompanyPixels = async (
  companyId: number,
  params: FetchDataParamsWithToken<keyof CompanyPixel>
): Promise<ListCompanyPixelsRO> => {
  const { data } = await apiService.get<ListCompanyPixelsRO>(
    Endpoints.Companies.Get.ListPixels(companyId),
    { params }
  );

  return data;
};

const listCompanyPixelCampaigns = async (
  companyId: number,
  pixelId: number,
  params: FetchDataParamsWithToken<keyof CampaignForList>
) => {
  const { data } = await apiService.get<ListCompanyPixelCampaignsRO>(
    Endpoints.Companies.Get.PixelCampaigns(companyId, pixelId),
    { params }
  );

  return data;
};

type PaginatedDomainsParams = {
  page: number;
  per_page: number;
  campaignId?: number;
};

const listAccounts =
  (
    pixelId: number,
    accountView: WebAnalyticsAccountView,
    startDate: string,
    endDate: string,
    campaignId?: number
  ) =>
  async (params: PaginatedDomainsParams) => {
    const query = getPaginatedQuery(
      Endpoints.WebAnalytics.Get.Accounts(pixelId),
      {
        ...{ ...params, accountView, campaignId, startDate, endDate },
        order: SortDirection.ASC,
        order_by: "displayName",
      }
    );

    const { data } = await apiService.get(query);
    return {
      meta: data.meta,
      links: data.links,
      results: data.results.map(
        (el: { displayName: string; domainId: number }) => ({
          value: el.domainId,
          label: el.displayName,
        })
      ),
    };
  };

type PaginatedVisitedPagesParams = {
  page: number;
  per_page: number;
  pixel: string;
  accountView: WebAnalyticsAccountView;
  campaignId: number | null;
  accountsIds?: number[];
  startDate: string;
  endDate: string;
};

const listVisitedPages =
  (
    pixelId: number,
    accountView: WebAnalyticsAccountView,
    startDate: string,
    endDate: string,
    campaignId: number | null,
    accountsIds?: number[]
  ) =>
  async (params: PaginatedVisitedPagesParams) => {
    const query = getPaginatedQuery<Item>(
      Endpoints.WebAnalytics.Get.VisitedPages(pixelId),
      {
        ...{
          ...params,
          accountView,
          startDate,
          endDate,
          campaignId,
          accountsIds,
        },
        order: SortDirection.ASC,
        order_by: "label",
        per_page: 50,
      }
    );

    const { data } = await apiService.get(query);
    return {
      meta: data.meta,
      links: data.links,
      results: data.results.map((el: { url: string }, index: number) => ({
        value:
          (data.meta.current_page - 1) * Number(data.meta.items_per_page) +
          index +
          1,
        label: el.url,
      })),
    };
  };

export {
  listCompanyPixels,
  listCompanyPixelCampaigns,
  listVisitedPages,
  listAccounts,
};
