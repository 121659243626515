import {
  Box,
  Spinner,
  Text,
  useColorModeValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { isPopulatedArray } from "@intentsify/utils";
import { GroupBase, MenuListProps } from "react-select";
import { Virtuoso } from "react-virtuoso";
import { ITEM_HEIGHT } from "./Select.const";

export const MenuList = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: MenuListProps<Option, IsMulti, Group> & {
    endReached?: () => void;
    testId?: string;
  }
) => {
  const { children, maxHeight, testId } = props;
  const menuStyles = useMultiStyleConfig("Menu", {});
  const minHeight = Array.isArray(children) ? children.length * ITEM_HEIGHT : 0;

  return (
    <Box
      data-testId={testId ? `${testId}-list-wrapper` : undefined}
      sx={{
        ...menuStyles.list,
        w: "100%",
      }}
    >
      {isPopulatedArray(children) ? (
        <Virtuoso
          totalCount={children.length}
          style={{ height: minHeight > maxHeight ? maxHeight : minHeight }}
          endReached={() => {
            props.endReached?.();
            props.selectProps?.onFetchDataChange?.();
          }}
          overscan={200}
          itemContent={(index) => {
            return <VirtuosoItem item={children[index]} />;
          }}
          context={{ isLoading: props.selectProps?.isLoading }}
          components={{ Footer: Footer as any }}
        />
      ) : (
        <Text fontSize="sm" color="gray.100" textAlign="center">
          No options
        </Text>
      )}
    </Box>
  );
};

const VirtuosoItem = ({ item }: { item: React.ReactNode }) => {
  if (!item) {
    return null;
  }
  return <div>{item}</div>;
};

const Footer = (props: { isLoading?: boolean }) => {
  const spinnerColor = useColorModeValue("gray.500", "gray.100");

  if (props.isLoading) {
    return (
      <Box
        style={{
          padding: "0.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spinner color={spinnerColor} size="sm" />
      </Box>
    );
  }

  return null;
};
