import {
  CompanyPixelRO,
  MutateCompanyPixelDeliveryRecipientDTO,
  CompanyPixelDeliveryRecipientRO,
} from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";
import {
  CompanyPixelDeliveryRecipient,
  SortDirection,
} from "@intentsify/types";

export const defaultPaginatedDeliveryRecipientsParams: FetchDataParamsWithToken<
  keyof CompanyPixelDeliveryRecipient
> = {
  pageToken: "",
  order_by: "deliveryRecipientId",
  order: SortDirection.DESC,
};

export const getCompanyPixel = async (companyId: number, pixelId: number) => {
  const { data } = await apiService.get<CompanyPixelRO>(
    Endpoints.Companies.Get.Pixel(companyId, pixelId)
  );

  return data;
};

export const listCompanyPixelDeliveryRecipients = async (
  companyId: number,
  pixelId: number,
  params: FetchDataParamsWithToken<keyof CompanyPixelDeliveryRecipient>
) => {
  const { data } = await apiService.get(
    Endpoints.Companies.Get.PixelDeliveryRecipients(companyId, pixelId),
    { params }
  );

  return data;
};

export const deleteCompanyPixelDeliveryRecipient = async (
  companyId: number,
  pixelId: number,
  deliveryRecipientId: number
) => {
  const { data } = await apiService.delete(
    Endpoints.Companies.Delete.PixelDeliveryRecipients(
      companyId,
      pixelId,
      deliveryRecipientId
    )
  );

  return data;
};

export const getCompanyPixelDeliveryRecipient = async (
  companyId: number,
  pixelId: number,
  deliveryRecipientId: number
) => {
  const { data } = await apiService.get<CompanyPixelDeliveryRecipientRO>(
    Endpoints.Companies.Get.PixelDeliveryRecipient(
      companyId,
      pixelId,
      deliveryRecipientId
    )
  );

  return data;
};

export const createCompanyPixelDeliveryRecipient = async (
  companyId: number,
  pixelId: number,
  params: MutateCompanyPixelDeliveryRecipientDTO
) => {
  const { data } = await apiService.post(
    Endpoints.Companies.Post.CreateCompanyPixelDeliveryRecipient(
      companyId,
      pixelId
    ),
    { ...params }
  );

  return data;
};

export const editCompanyPixelDeliveryRecipient = async (
  companyId: number,
  pixelId: number,
  deliveryRecipientId: number,
  params: MutateCompanyPixelDeliveryRecipientDTO
) => {
  const { data } = await apiService.put(
    Endpoints.Companies.Put.EditCompanyPixelDeliveryRecipient(
      companyId,
      pixelId,
      deliveryRecipientId
    ),
    { ...params }
  );

  return data;
};
