import { Flex, ButtonGroup } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Search, Table } from "components";
import { useCallback, useMemo, useState } from "react";
import { useTrackSearched } from "../../../../../tracking/useTrackSearched";
import { listPaginatedPageTracking } from "../PageTracking.requests";
import {
  defaultListPaginatedPageTrackingParams,
  PageTrackingFetchDataParams,
} from "../PageTracking.types";
import { pageTrackingTableColumns } from "./PageTracking.columns";
import { useUserAgencyCompany } from "../../../../../queries/companies/useUserAgencyCompany";
import { CompanyPixelForList } from "@intentsify/types";
import { PaginationType } from "../../../../../components/Table/Table/Table.types";
import { FiDownload, FiEdit } from "react-icons/fi";
import { CreatePixelScreenDefinition } from "screens";
import { useNavigate } from "react-router";
import { Endpoints } from "api";
import { useDownloadFile } from "utils";

const PageTrackingTable = () => {
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const columns = useMemo<ColumnDef<CompanyPixelForList>[]>(
    () => pageTrackingTableColumns,
    []
  );

  const [fetchDataParams, setFetchDataParams] =
    useState<PageTrackingFetchDataParams>(
      defaultListPaginatedPageTrackingParams
    );

  const { data, isFetching } = useQuery({
    queryKey: ["listCompanyPixels", selectedCompanyId, fetchDataParams],
    queryFn: async () => {
      return listPaginatedPageTracking(
        Number(selectedCompanyId),
        fetchDataParams
      );
    },
    enabled: !!selectedCompanyId,
  });

  const handleOnFetchDataChange = useCallback(
    (params: PageTrackingFetchDataParams) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  const { download } = useDownloadFile();

  const handleDownloadInstructions = async () => {
    await download({
      url: `${Endpoints.Companies.Get.DownloadInstructions}`,
      fileName: "intentsify-script-instructions",
      fileExtension: "zip",
    });
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        marginY={4}
        alignItems="center"
      >
        <Search
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({
              term: v,
              collocation: "Page tracking table",
            });
          }}
          mb={0}
        />
        <ButtonGroup marginLeft={1}>
          <Button
            variant="primary-teal"
            rightIcon={<FiEdit />}
            size="sm"
            onClick={() => {
              navigate(CreatePixelScreenDefinition.navigate());
            }}
          >
            Create Pixel
          </Button>
          <Button
            variant="outline-teal"
            rightIcon={<FiDownload />}
            size="sm"
            onClick={() => {
              handleDownloadInstructions();
            }}
          >
            Download Deployment Instructions
          </Button>
        </ButtonGroup>
      </Flex>
      <Table
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[{ id: "pixelId", desc: true }]}
        columns={columns}
        isFetching={isFetching}
        data={data?.companyPixels || []}
        onFetchDataChange={handleOnFetchDataChange}
        resetPaginationDependencies={[searchValue, selectedCompanyId]}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
      />
    </>
  );
};

export { PageTrackingTable };
