import { Box, Flex, Text as HTMLText } from "@chakra-ui/layout";
import { formatDate, isDefined, isPopulatedArray } from "@intentsify/utils";
import {
  AxisBottom,
  AxisLeft,
  AxisRight,
  AxisTop,
  Orientation,
} from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear, scaleLog, scaleOrdinal } from "@visx/scale";
import { BarStack, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { DateTime } from "luxon";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { extractTextFromMaybeJSX, useChartColors } from "utils";
import { ErrorBoundary } from "../../ErrorBoundary";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { BasicTooltip } from "../BasicTooltip";
import {
  EXPORT_TITLE_HEIGHT,
  MIN_HEIGHT,
  X_AXIS_LABEL_GAP,
  Y_AXIS_LABEL_SPACE,
} from "../Charts.const";
import {
  TimeseriesDataItem,
  TimeseriesLegendItem,
  TimeseriesTooltipItem,
  XAxisLabel,
} from "../Charts.types";
import {
  formatTickValue,
  getWrapperDirection,
  measureTextWidth,
  trimTextByWidth,
} from "../Charts.utils";
import { useGetColour, useTimeseriesData } from "./Timeseries.hooks";
import {
  findXAxisLabelFrequency,
  muteTimeseriesColor,
} from "./Timeseries.utils";
import {
  Legend,
  LinesGroup,
  Markers,
  MarkersProps,
  TimeseriesTooltip,
  TimeseriesTooltipProps,
} from "./components";

export type TimeseriesProps = {
  isLoading?: boolean;
  leftAxisLines?: Array<{
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItem;
  }>;
  rightAxisLines?: Array<{
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItem;
  }>;
  leftAxisBars?: Array<{
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItem;
  }>;
  rightAxisBars?: Array<{
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItem;
  }>;
  id?: string;
  exportMode?: true;
  title?: string;
  disableLegend?: boolean;
  disableDataSources?: boolean;
  markers?: MarkersProps["markers"];
  xAxisTimeGap?: number;
  customLegend?: Array<{
    section: string;
    items: Array<{
      type?: "text" | "subheader";
      // even though it allows React.ReactElement it's only supports for some custom sematic and not all possible DOM
      // structures
      label: string | React.ReactElement;
    }>;
  }>;

  legendPosition?: "bottom" | "right";
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  noDataMessage?: string;
  fixedTooltip?: boolean;
  barsTooltipRenderer?: (props: TimeseriesTooltipProps) => ReactNode;
  leftAxisLinesTooltipRenderer?: (props: TimeseriesTooltipProps) => ReactNode;
  rightAxisLinesTooltipRenderer?: (props: TimeseriesTooltipProps) => ReactNode;
  scale?: "linear" | "log";
  leftAxisLabel?: string;
  rightAxisLabel?: string;
  minHeight?: string;
  skipDecimals?: boolean;
  xAxisLabels?: XAxisLabel[];
  timesScalePadding?: { paddingInner: number; paddingOuter: number };
  displayLinesInFrontOfBars?: boolean;
};

const TimeseriesNew = ({
  leftAxisBars,
  rightAxisBars,
  leftAxisLines,
  rightAxisLines,
  id,
  title,
  exportMode = undefined,
  disableLegend = false,
  legendPosition = undefined,
  isLoading = false,
  margins = {
    t: 20,
    b: 70,
    r: 45,
    l: 45,
  },
  stretch = false,
  markers = [],
  noDataMessage,
  fixedTooltip = false,
  scale = "linear",
  minHeight = MIN_HEIGHT,
  xAxisTimeGap,
  leftAxisLabel,
  rightAxisLabel,
  barsTooltipRenderer = TimeseriesTooltip,
  leftAxisLinesTooltipRenderer = TimeseriesTooltip,
  rightAxisLinesTooltipRenderer = TimeseriesTooltip,
  customLegend = [],
  skipDecimals,
  xAxisLabels = [],
  timesScalePadding = { paddingInner: 0.3, paddingOuter: 0.3 },
  displayLinesInFrontOfBars = false,
}: TimeseriesProps) => {
  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>();

  const {
    barItemsWithAxis,
    groupedLegendItems,
    sorted,
    leftAxisValues,
    rightAxisValues,
    timeValues,
  } = useTimeseriesData(
    leftAxisBars,
    rightAxisBars ?? [],
    leftAxisLines,
    rightAxisLines,
    {
      markers,
      timeGap: xAxisTimeGap,
    }
  );

  const [legendSideWidth, exportLegendWidth] = useMemo(() => {
    const widths = Object.values(groupedLegendItems)
      .flat()
      .map((i) => i.legend.label)
      .map((i) => measureTextWidth(i, { fontSize: 14 }));

    const hasCustomLegends = Boolean(customLegend?.length);

    // if there custom legend space for legend should be doubled
    // we ignore actual width of custom legend as it's should be trimmed
    const singleLegendSideWidth = Math.max(...widths) + 50;
    return [
      singleLegendSideWidth,
      singleLegendSideWidth * (hasCustomLegends ? 2 : 1),
    ];
  }, [groupedLegendItems, customLegend?.length]);

  const timeScale = useRef<ScaleBand<string> | undefined>();
  const leftAxisValuesScale = useRef<
    ScaleLinear<number, number, never> | undefined
  >();
  const rightAxisValuesScale = useRef<
    ScaleLinear<number, number, never> | undefined
  >();

  const { labelColor, axisColors } = useChartColors();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    isoDate: string;
    data: TimeseriesTooltipItem[];
  }>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: !fixedTooltip,
    scroll: true,
  });

  const resetInteractions = useCallback(() => {
    if (tooltipOpen) {
      hideTooltip();
    }
  }, [tooltipOpen, hideTooltip]);

  useEffect(() => {
    window.addEventListener("scroll", resetInteractions);

    return () => {
      window.removeEventListener("scroll", resetInteractions);
    };
  }, [resetInteractions]);

  const [{ scale: springScale }] = useSpring(
    {
      from: { scale: 0 },
      to: { scale: 1 },
      reset: true,
    },
    [isLoading]
  );

  const getColour = useGetColour();

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(timeValues)) {
    return <NoData message={noDataMessage} stretch={stretch} height="auto" />;
  }

  const legendPositionComputed = legendPosition
    ? legendPosition
    : containerWidth > 1000
    ? "right"
    : "bottom";

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%">
        <Flex
          flexDirection={getWrapperDirection(legendPositionComputed)}
          width="100%"
        >
          <Flex
            width="100%"
            flexGrow={stretch ? 1 : 0}
            minHeight={minHeight}
            overflow="hidden"
          >
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                const baseYMax = height - margins.t - margins.b;
                const baseXMax =
                  width -
                  margins.l -
                  margins.r -
                  (leftAxisLabel ? Y_AXIS_LABEL_SPACE : 0) -
                  (rightAxisLabel ? Y_AXIS_LABEL_SPACE : 0);

                const yMax = !exportMode
                  ? baseYMax
                  : baseYMax - EXPORT_TITLE_HEIGHT;

                const xMax = !exportMode
                  ? baseXMax
                  : baseXMax - exportLegendWidth;

                timeScale.current = scaleBand<string>({
                  range: [0, xMax],
                  round: true,
                  domain: timeValues,
                  paddingOuter: timesScalePadding.paddingOuter,
                  paddingInner: timesScalePadding.paddingInner,
                });

                leftAxisValuesScale.current =
                  scale === "linear"
                    ? scaleLinear<number>({
                        domain: [0, Math.max(...leftAxisValues)],
                        range: [yMax, 0],
                        nice: true,
                      })
                    : scaleLog<number>({
                        domain: [
                          Math.min(...leftAxisValues),
                          Math.max(...leftAxisValues),
                        ],
                        range: [yMax, 0],
                      });

                rightAxisValuesScale.current =
                  scale === "linear"
                    ? scaleLinear<number>({
                        domain: [0, Math.max(...rightAxisValues)],
                        range: [yMax, 0],
                        nice: true,
                      })
                    : scaleLog<number>({
                        domain: [
                          Math.min(...rightAxisValues),
                          Math.max(...rightAxisValues),
                        ],
                        range: [yMax, 0],
                      });

                const xLabelFrequency = findXAxisLabelFrequency({
                  width: xMax,
                  itemsCount: timeValues.length,
                });

                return (
                  <Box
                    width={width}
                    height={height}
                    position="relative"
                    onMouseLeave={() => resetInteractions()}
                  >
                    <svg
                      width={width}
                      height={height}
                      ref={containerRef}
                      id={id}
                      style={{
                        fontFamily: "var(--chakra-fonts-body)",
                      }}
                    >
                      <rect
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        fill={"transparent"}
                        id="wrapper"
                        onMouseMove={(e: React.MouseEvent) => {
                          if (
                            e.currentTarget.getAttribute("id") === "wrapper"
                          ) {
                            resetInteractions();
                          }
                        }}
                      />

                      {exportMode && title && (
                        <Group top={30} left={22}>
                          <Text
                            verticalAnchor="middle"
                            fontSize={22}
                            fontWeight="semibold"
                            fill={labelColor}
                          >
                            {title}
                          </Text>
                        </Group>
                      )}

                      <Group>
                        <g
                          transform={`translate(${
                            margins.l + (leftAxisLabel ? Y_AXIS_LABEL_SPACE : 0)
                          },${
                            !exportMode
                              ? margins.t
                              : margins.t + EXPORT_TITLE_HEIGHT
                          })`}
                        >
                          {xAxisLabels.length > 0 && (
                            <AxisTop
                              top={-X_AXIS_LABEL_GAP}
                              orientation={Orientation.bottom}
                              tickValues={timeValues}
                              scale={timeScale.current}
                              // @ts-ignore
                              tickFormat={(val) => val}
                            >
                              {(props) => {
                                const tickLabelSize = 12;

                                return (
                                  <g>
                                    {props.ticks.map((tick, i) => {
                                      const tickX = tick.to.x;
                                      const nextTick = props.ticks[i + 1];

                                      const cellWidth = nextTick
                                        ? nextTick.to.x - tickX
                                        : 0;

                                      return (
                                        <Group
                                          key={`vx-tick-${String(
                                            tick.value
                                          )}-${i}`}
                                          className={"vx-axis-tick"}
                                        >
                                          <g>
                                            <line
                                              x1={tickX}
                                              y1={10}
                                              x2={tickX + cellWidth}
                                              y2={10}
                                              stroke={labelColor}
                                              strokeWidth={1}
                                            />
                                            <line
                                              x1={tickX}
                                              y1={1}
                                              x2={tickX}
                                              y2={20}
                                              stroke={labelColor}
                                              strokeWidth={1}
                                            />
                                            <line
                                              x1={tickX + cellWidth}
                                              y1={1}
                                              x2={tickX + cellWidth}
                                              y2={20}
                                              stroke={labelColor}
                                              strokeWidth={1}
                                            />
                                          </g>

                                          {/* Draw the label */}
                                          {i % xLabelFrequency === 0 && (
                                            <text
                                              cursor="default"
                                              transform={`translate(${
                                                tickX + cellWidth / 2
                                              }, ${tick.to.y - 10})`}
                                              fontSize={tickLabelSize}
                                              textAnchor="middle"
                                              dominantBaseline={"middle"}
                                              fill={labelColor}
                                            >
                                              {
                                                xAxisLabels.find(
                                                  (l) =>
                                                    l.isoDate === tick.value
                                                )?.label
                                              }
                                            </text>
                                          )}
                                        </Group>
                                      );
                                    })}
                                  </g>
                                );
                              }}
                            </AxisTop>
                          )}

                          {timeScale.current && !displayLinesInFrontOfBars && (
                            <>
                              <LinesGroup
                                timeValues={timeValues}
                                timeScale={timeScale.current}
                                leftAxisValuesScale={
                                  leftAxisValuesScale.current
                                }
                                rightAxisValuesScale={
                                  rightAxisValuesScale.current
                                }
                                offsetLeft={
                                  margins.l +
                                  (leftAxisLabel ? Y_AXIS_LABEL_SPACE : 0)
                                }
                                offsetTop={margins.t}
                                sorted={sorted}
                                tooltipInPortal={TooltipInPortal}
                                leftAxisLinesTooltipRenderer={
                                  leftAxisLinesTooltipRenderer
                                }
                                rightAxisLinesTooltipRenderer={
                                  rightAxisLinesTooltipRenderer
                                }
                              />

                              {markers.length > 0 && (
                                <Markers
                                  markers={markers}
                                  height={yMax}
                                  timeScale={timeScale.current}
                                  offsetLeft={
                                    margins.l +
                                    (leftAxisLabel ? Y_AXIS_LABEL_SPACE : 0)
                                  }
                                  offsetTop={
                                    margins.t -
                                    (xAxisLabels.length > 0
                                      ? X_AXIS_LABEL_GAP
                                      : 0)
                                  }
                                  tooltipInPortal={TooltipInPortal}
                                />
                              )}
                            </>
                          )}

                          {timeValues.map((t) => {
                            if (
                              !leftAxisValuesScale.current ||
                              !timeScale.current
                            ) {
                              return null;
                            }

                            const itemsForDate = barItemsWithAxis.filter(
                              (i) => i.isoDate === t
                            );

                            const bandwidth = timeScale.current.bandwidth();
                            const x = timeScale.current(t) as number;

                            const subitemDomain = itemsForDate.map((_, index) =>
                              String(index)
                            );

                            const subitemScale = scaleBand<string>({
                              range: [x, x + bandwidth],
                              round: true,
                              domain: subitemDomain,
                              paddingInner: 0,
                              paddingOuter: 0.05,
                            });

                            return itemsForDate.map((item, index) => {
                              const keys = ["value"];

                              if (!leftAxisValuesScale.current) {
                                return null;
                              }

                              const colorScale = scaleOrdinal<string, string>({
                                domain: keys,
                                range: [...getColour(item.legend.color)],
                              });

                              const data = {
                                label: String(index),
                                value: item.value,
                              };

                              return (
                                <Fragment key={index}>
                                  <BarStack
                                    key={t}
                                    data={[data]}
                                    keys={keys}
                                    x={(d) => d.label}
                                    xScale={subitemScale}
                                    yScale={
                                      item.axis === "right" &&
                                      rightAxisValuesScale.current
                                        ? rightAxisValuesScale.current
                                        : leftAxisValuesScale.current
                                    }
                                    color={colorScale}
                                  >
                                    {(barStacks) =>
                                      barStacks.map((barStack) =>
                                        barStack.bars.map((bar) => {
                                          return (
                                            <animated.rect
                                              key={`bar-stack-${barStack.index}-${bar.index}`}
                                              x={bar.x}
                                              y={springScale.to(
                                                (s) =>
                                                  s * bar.y + (yMax - yMax * s)
                                              )}
                                              height={springScale.to(
                                                (s) => s * bar.height
                                              )}
                                              width={bar.width}
                                              fill={
                                                item.legend.muteColor
                                                  ? muteTimeseriesColor(
                                                      bar.color
                                                    )
                                                  : bar.color
                                              }
                                              onMouseMove={(event) => {
                                                if (event.target) {
                                                  const target = event.target;

                                                  const coords = localPoint(
                                                    // @ts-expect-error
                                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                                    target.ownerSVGElement,
                                                    event
                                                  );

                                                  if (coords) {
                                                    showTooltip({
                                                      tooltipLeft: coords.x,
                                                      tooltipTop: coords.y,
                                                      tooltipData: {
                                                        isoDate:
                                                          itemsForDate[0]
                                                            .isoDate,
                                                        data: itemsForDate.map(
                                                          (i) => ({
                                                            label:
                                                              i.legend.label,
                                                            value: i.value,
                                                            color:
                                                              i.legend.color,
                                                          })
                                                        ),
                                                      },
                                                    });
                                                  }
                                                }
                                              }}
                                              onMouseLeave={() => {
                                                hideTooltip();
                                              }}
                                            />
                                          );
                                        })
                                      )
                                    }
                                  </BarStack>
                                </Fragment>
                              );
                            });
                          })}

                          {timeScale.current && displayLinesInFrontOfBars && (
                            <>
                              <LinesGroup
                                timeValues={timeValues}
                                timeScale={timeScale.current}
                                leftAxisValuesScale={
                                  leftAxisValuesScale.current
                                }
                                rightAxisValuesScale={
                                  rightAxisValuesScale.current
                                }
                                offsetLeft={
                                  margins.l +
                                  (leftAxisLabel ? Y_AXIS_LABEL_SPACE : 0)
                                }
                                offsetTop={margins.t}
                                sorted={sorted}
                                tooltipInPortal={TooltipInPortal}
                                leftAxisLinesTooltipRenderer={
                                  leftAxisLinesTooltipRenderer
                                }
                                rightAxisLinesTooltipRenderer={
                                  rightAxisLinesTooltipRenderer
                                }
                              />

                              {markers.length > 0 && (
                                <Markers
                                  markers={markers}
                                  height={yMax}
                                  timeScale={timeScale.current}
                                  offsetLeft={
                                    margins.l +
                                    (leftAxisLabel ? Y_AXIS_LABEL_SPACE : 0)
                                  }
                                  offsetTop={margins.t}
                                  tooltipInPortal={TooltipInPortal}
                                />
                              )}
                            </>
                          )}

                          <AxisLeft
                            label={leftAxisLabel}
                            labelProps={{
                              fill: labelColor,
                              fontSize: 12,
                              textAnchor: "middle",
                              verticalAnchor: "middle",
                              y: -margins.l - Y_AXIS_LABEL_SPACE / 2,
                            }}
                            stroke={axisColors}
                            tickStroke={axisColors}
                            scale={leftAxisValuesScale.current}
                            tickValues={leftAxisValuesScale.current
                              .ticks(10)
                              .filter((value) =>
                                skipDecimals ? Number.isInteger(value) : true
                              )}
                            tickFormat={
                              scale === "log"
                                ? (v) => {
                                    const asString = `${String(v)}`;
                                    // label only major ticks
                                    return asString.match(/^[.01?[\]]*$/)
                                      ? formatTickValue(Number(v))
                                      : "";
                                  }
                                : (v) => formatTickValue(Number(v))
                            }
                            tickComponent={(props) => {
                              const {
                                formattedValue,
                                x,
                                y,
                                textAnchor,
                                verticalAnchor,
                                dx,
                                dy,
                              } = props;

                              return (
                                <Text
                                  x={x}
                                  y={y}
                                  dx={dx}
                                  dy={dy}
                                  fontSize={12}
                                  textAnchor={textAnchor}
                                  verticalAnchor={verticalAnchor}
                                  fill={labelColor}
                                >
                                  {formattedValue}
                                </Text>
                              );
                            }}
                          />

                          {(rightAxisBars || rightAxisLines) && (
                            <AxisRight
                              label={rightAxisLabel}
                              labelProps={{
                                fill: labelColor,
                                fontSize: 12,
                                textAnchor: "middle",
                                verticalAnchor: "middle",
                                y: -margins.r - Y_AXIS_LABEL_SPACE / 2,
                              }}
                              left={xMax}
                              stroke={axisColors}
                              tickStroke={axisColors}
                              scale={rightAxisValuesScale.current}
                              tickFormat={
                                scale === "log"
                                  ? (v) => {
                                      const asString = `${String(v)}`;
                                      // label only major ticks
                                      return asString.match(/^[.01?[\]]*$/)
                                        ? formatTickValue(Number(v))
                                        : "";
                                    }
                                  : (v) => formatTickValue(Number(v))
                              }
                              tickValues={rightAxisValuesScale.current
                                .ticks(10)
                                .filter((value) =>
                                  skipDecimals ? Number.isInteger(value) : true
                                )}
                              tickComponent={(props) => {
                                const {
                                  formattedValue,
                                  x,
                                  y,
                                  textAnchor,
                                  verticalAnchor,
                                  dx,
                                  dy,
                                } = props;

                                return (
                                  <Text
                                    x={x}
                                    y={y}
                                    dx={dx}
                                    dy={dy}
                                    fontSize={12}
                                    textAnchor={textAnchor}
                                    verticalAnchor={verticalAnchor}
                                    fill={labelColor}
                                  >
                                    {formattedValue}
                                  </Text>
                                );
                              }}
                            />
                          )}

                          <AxisBottom
                            top={yMax}
                            orientation={Orientation.bottom}
                            tickValues={timeValues}
                            scale={timeScale.current}
                            // @ts-ignore
                            tickFormat={(val) => val}
                          >
                            {(props) => {
                              const tickLabelSize = 10;
                              const tickRotate = -45;

                              const { axisFromPoint, axisToPoint } = props;

                              return (
                                <g>
                                  <Line
                                    from={axisFromPoint}
                                    to={axisToPoint}
                                    stroke={axisColors}
                                  />

                                  {props.ticks.map((tick, i) => {
                                    const label = formatDate({
                                      date: DateTime.fromISO(
                                        tick.value
                                      ).toJSDate(),
                                    });

                                    const tickX = tick.to.x;
                                    const tickY = tick.to.y + 10;

                                    return (
                                      <Group
                                        key={`vx-tick-${String(
                                          tick.value
                                        )}-${i}`}
                                        className={"vx-axis-tick"}
                                      >
                                        <Line
                                          from={tick.from}
                                          to={tick.to}
                                          stroke={axisColors}
                                        />

                                        {i % xLabelFrequency === 0 && (
                                          <text
                                            cursor="default"
                                            transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                            fontSize={tickLabelSize}
                                            textAnchor="end"
                                            fill={labelColor}
                                          >
                                            {label}
                                          </text>
                                        )}
                                      </Group>
                                    );
                                  })}
                                </g>
                              );
                            }}
                          </AxisBottom>
                        </g>
                      </Group>

                      {exportMode && (
                        <>
                          <Group
                            width={`${legendSideWidth}px`}
                            top={30}
                            left={width - exportLegendWidth + 16}
                          >
                            {Object.entries(groupedLegendItems).map(
                              ([group, items], groupIndex) => {
                                const groupOffset = group ? 1 : 0;
                                const prevGroupHeight = Object.entries(
                                  groupedLegendItems
                                )
                                  .slice(0, groupIndex)
                                  .reduce(
                                    (acc, [prevGroup, items]) =>
                                      acc +
                                      (items.length + (prevGroup ? 1 : 0)) * 32,
                                    0
                                  );
                                return (
                                  <Group
                                    key={group ?? groupIndex}
                                    top={prevGroupHeight}
                                  >
                                    {group && (
                                      <Text
                                        verticalAnchor="middle"
                                        fontSize={14}
                                        fontWeight="bold"
                                        fill={labelColor}
                                      >
                                        {group}
                                      </Text>
                                    )}
                                    {items.map((item, index) => (
                                      <Fragment key={`${group}_${index}`}>
                                        <rect
                                          x={0}
                                          y={(index + groupOffset) * 32 - 8}
                                          width={16}
                                          height={16}
                                          fill={getColour(item.legend.color)[0]}
                                        />

                                        <Text
                                          verticalAnchor="middle"
                                          fontSize={14}
                                          width={exportLegendWidth}
                                          x={24}
                                          y={(index + groupOffset) * 32}
                                          fill={labelColor}
                                        >
                                          {item.legend.label}
                                        </Text>
                                      </Fragment>
                                    ))}
                                  </Group>
                                );
                              }
                            )}
                          </Group>
                          {customLegend && customLegend.length > 0 && (
                            <Group
                              width={`${legendSideWidth}px`}
                              top={30}
                              left={
                                width - exportLegendWidth + legendSideWidth + 16
                              }
                            >
                              {customLegend.map(({ section, items }) => (
                                <>
                                  <Text
                                    verticalAnchor="middle"
                                    fontSize={14}
                                    fontWeight="bold"
                                    fill={labelColor}
                                  >
                                    {section}
                                  </Text>
                                  {items.map((item, index) => (
                                    <Text
                                      key={index}
                                      verticalAnchor="middle"
                                      fontSize={14}
                                      fontWeight={
                                        item.type === "subheader"
                                          ? "bold"
                                          : "normal"
                                      }
                                      width={exportLegendWidth}
                                      y={(index + 1) * 32}
                                      fill={labelColor}
                                    >
                                      {trimTextByWidth(
                                        extractTextFromMaybeJSX(item.label),
                                        {
                                          fontSize: 14,
                                          maxWidth: legendSideWidth - 24,
                                        }
                                      )}
                                    </Text>
                                  ))}
                                </>
                              ))}
                            </Group>
                          )}
                        </>
                      )}
                    </svg>

                    {tooltipOpen &&
                      tooltipData &&
                      isDefined(tooltipLeft) &&
                      isDefined(tooltipTop) && (
                        <BasicTooltip
                          tooltipRenderer={({ textColor, tooltipData }) => {
                            if (!barsTooltipRenderer) {
                              return (
                                <>
                                  You need to provide barsTooltipRenderer prop
                                </>
                              );
                            }

                            return (
                              <>
                                {barsTooltipRenderer({
                                  textColor,
                                  isoDate: tooltipData.isoDate,
                                  data: tooltipData.data,
                                })}
                              </>
                            );
                          }}
                          TooltipInPortal={TooltipInPortal}
                          tooltipLeft={tooltipLeft}
                          tooltipTop={tooltipTop}
                          tooltipData={tooltipData}
                        />
                      )}
                  </Box>
                );
              }}
            </ParentSize>
          </Flex>

          {!disableLegend && !exportMode && (
            <>
              <Flex flexDirection="column">
                {Object.entries(groupedLegendItems).map(
                  ([group, legendItems], index) => (
                    <Box key={group ?? index}>
                      {group && (
                        <HTMLText
                          fontSize="md"
                          fontWeight="bold"
                          ml={
                            legendPositionComputed === "right" ? 5 : undefined
                          }
                        >
                          {group}
                        </HTMLText>
                      )}
                      <Flex
                        flexDirection="column"
                        width={
                          legendPositionComputed === "bottom"
                            ? "100%"
                            : undefined
                        }
                        mt={legendPositionComputed === "bottom" ? 5 : undefined}
                        ml={legendPositionComputed === "right" ? 5 : undefined}
                      >
                        <Legend
                          legendPosition={legendPositionComputed}
                          items={legendItems}
                        />
                      </Flex>
                    </Box>
                  )
                )}
              </Flex>
              {Boolean(customLegend.length) && (
                <Flex flexDirection="column" maxW={250}>
                  {customLegend.map((legendItem) => (
                    <Box key={legendItem.section}>
                      <Flex flexDirection="row" gap={1}>
                        <HTMLText fontSize="md" fontWeight="bold">
                          {legendItem.section}
                        </HTMLText>
                      </Flex>
                      {legendItem.items.map((item, index) => (
                        <Box
                          key={index}
                          whiteSpace="nowrap"
                          fontSize="small"
                          fontWeight={
                            item.type === "subheader" ? "bold" : "normal"
                          }
                          my="5px"
                        >
                          {item.label}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { TimeseriesNew };
