import { useQuery } from "@tanstack/react-query";
import { getSiteEngagementLift } from "../../Visualization.requests";
import {
  formatDate,
  getExportFileName,
  isPopulatedArray,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { useMemo } from "react";
import {
  ChartExport,
  useCampaignMeta,
  useExportableChart,
} from "../../../../../../../../shared/components";
import { TimeseriesNew } from "../../../../../../../../components/Charts/TimeseriesNew";
import { Card, DataToCsvDownload, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { FaHashtag } from "react-icons/fa";
import { Flex } from "@chakra-ui/react";
import { Cards } from "./components";
import { SiteEngagementLiftRO } from "@intentsify/dto";
import { DateTime } from "luxon";

const useCsvExportData = (data: SiteEngagementLiftRO | undefined) => {
  const chartData = useMemo(() => {
    if (!data) {
      return null;
    }

    return {
      cards: data.cards.map((i) => ({
        ["Period"]: i.period,
        ["Average Site Engagement"]: toNumberDisplayValue(
          i.averageSiteEngagement ?? 0
        ),
        ["Engagement Change"]: `${toNumberDisplayValue(
          (i.engagementChange || 0) * 100
        )}%`,
        ["Total Accounts"]: toNumberDisplayValue(i.totalAccounts),
        ["Inactive Accounts"]: toNumberDisplayValue(i.stoppedAccounts),
        ["New Accounts"]: toNumberDisplayValue(i.newAccounts),
        ["Engagement From New Accounts"]: `${toNumberDisplayValue(
          (i.engagementFromNewAccounts || 0) * 100
        )}%`,
      })),
      chart: [...data.timeseries]
        .sort(
          (a, b) =>
            DateTime.fromISO(b.date, { zone: "utc" }).toMillis() -
            DateTime.fromISO(a.date, { zone: "utc" }).toMillis()
        )
        .map((i) => ({
          ["Date"]: formatDate({
            date: DateTime.fromISO(i.date, { zone: "utc" }).toJSDate(),
          }),
          ["Impressions Count"]: toNumberDisplayValue(i.impressionsCount ?? 0),
          ["Site Engagement"]: toNumberDisplayValue(i.siteEngagement ?? 0),
          ["Average Site Engagement"]: toNumberDisplayValue(
            i.averageSiteEngagement ?? 0
          ),
        })),
    };
  }, [data]);

  return chartData;
};

export const SiteEngagementLift = ({ campaignId }: { campaignId: number }) => {
  const { data: data, isFetching: isFetching } = useQuery({
    queryKey: ["webAnalyticsSiteEngagementLift", campaignId],
    queryFn: () => getSiteEngagementLift(campaignId),
  });

  const { campaignMeta } = useCampaignMeta({
    campaignId,
  });

  const exportData = useCsvExportData(data);

  const exportFileNameTable = getExportFileName({
    prefix: `site_engagement_lift`,
    data: { id: campaignId },
    extension: ".csv",
  });

  const timeseriesData = useMemo(() => {
    const timeseries = data?.timeseries;

    if (!isPopulatedArray(timeseries)) {
      return null;
    }

    return {
      averageSiteEngagement: timeseries.map((i) => ({
        isoDate: i.date,
        value: i.averageSiteEngagement ?? 0,
      })),
      impressions: timeseries.map((i) => ({
        isoDate: i.date,
        value: i.impressionsCount ?? 0,
      })),
      siteEngagement: timeseries.map((i) => ({
        isoDate: i.date,
        value: i.siteEngagement ?? 0,
      })),
    };
  }, [data?.timeseries]);

  const chart = useExportableChart({
    title: "Site Engagement Lift",
    campaignId: Number(campaignId),
    chart: (
      <TimeseriesNew
        stretch
        leftAxisLabel="Impressions"
        rightAxisLabel="Site Engagement"
        rightAxisLines={[
          {
            data: timeseriesData?.averageSiteEngagement ?? [],
            legend: { label: "Average Site Engagement", color: "blue" },
          },
        ]}
        leftAxisBars={[
          {
            data: timeseriesData?.impressions ?? [],
            legend: {
              label: "Impressions",
              color: "yellow",
            },
          },
        ]}
        rightAxisBars={[
          {
            data: timeseriesData?.siteEngagement ?? [],
            legend: {
              label: "Site Engagement",
              color: "green",
            },
          },
        ]}
      />
    ),
  });

  // if it is not a display campaign do not show the chart
  if (!campaignMeta?.hasRelatedProgrammaticCampaigns) {
    return null;
  }

  return (
    <Card
      isLoading={isFetching}
      title="Site Engagement Lift"
      isExpandable
      hasData={
        isPopulatedArray(data?.cards) && isPopulatedArray(data?.timeseries)
      }
      noDataIcon={FaHashtag}
      noDataMessage={"This visualization is designated for display campaigns."}
    >
      {(isExpanded) => {
        return (
          <>
            {isExpanded ? (
              <Flex gap={8} flexDir="column">
                <Flex height={MIN_MODAL_CONTENT_HEIGHT}>{chart.component}</Flex>
                <Flex>
                  <Cards data={data?.cards ?? []} />
                </Flex>
              </Flex>
            ) : (
              <Flex minH="300px" gap={8}>
                <Flex w="50%" flexDir="column">
                  <Flex justifyContent="end">
                    <ChartExport
                      size="small"
                      campaignId={campaignId}
                      title={chart.title}
                      onExportPNG={chart.downloadAsPng}
                      data={exportData?.chart ?? []}
                    />
                  </Flex>

                  {chart.component}
                  {chart.exportComponent}
                </Flex>
                <Flex w="50%" flexDir="column">
                  <Flex justifyContent="end">
                    <DataToCsvDownload
                      withLabel={false}
                      fileName={exportFileNameTable}
                      data={exportData?.cards ?? []}
                      downloadLabel="Site Engagement Lift"
                      iconSize={"xs"}
                      tooltipPlacement="bottom-end"
                    />
                  </Flex>
                  <Cards data={data?.cards ?? []} />
                </Flex>
              </Flex>
            )}
          </>
        );
      }}
    </Card>
  );
};
