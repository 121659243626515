import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
  UnorderedList,
  chakra,
} from "@chakra-ui/react";
import { HasAccess } from "@intentsify/authorization/dist/react";
import {
  CampaignAdvancedSettings,
  CampaignDeliveryRecipientType,
  CompanyPixel,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import {
  DynamicInput,
  FilterAsyncTokenBased,
  useDynamicInput,
} from "components";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MutateCampaignAdvancedSettingsParams } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.requests";
import { useComponentColors } from "theme";
import { FetchDataParamsWithToken, WithCampaignId } from "types";
import { NavigationBlockers } from "../../NavigationBlockers/NavigationBlockers";
import { SubmitTrigger } from "../../SettingsStep/SettingsForm";
import { listCompanyPixels } from "../ActivationSettingsStep.requests";
import { CampaignActivationSettingsInputs } from "../ActivationSettingsStep.types";
import { useWatchActivationSettings } from "../ActivationSettingsStep.utils";
import { defaultListPixelsParams } from "screens";
import { DeliveryRecipients } from "./DeliveryRecipients";

type ActivationSettingsFormProps = {
  id: number;
  campaignAdvancedSettings: CampaignAdvancedSettings;
  onSubmit: (params: MutateCampaignAdvancedSettingsParams) => void;
  companyId: number;
};

const ActivationSettingsForm = forwardRef<
  SubmitTrigger | undefined,
  ActivationSettingsFormProps
>(({ id, campaignAdvancedSettings, onSubmit, companyId }, ref) => {
  const componentColors = useComponentColors();

  const [intentDataDeliveryRecipients, setIntentDataDeliveryRecipients] =
    useState<string[]>(
      campaignAdvancedSettings.deliveryRecipients
        .filter((e) => e.type === CampaignDeliveryRecipientType.Intent)
        .map((e: { email: string }) => e.email)
    );

  const [hasAutomatedIntentDelivery, setHasAutomatedIntentDelivery] = useState(
    campaignAdvancedSettings.automatedIntentDeliveryEnabled
  );

  const [showBuyingGroupContactDetails, setShowBuyingGroupContactDetails] =
    useState(campaignAdvancedSettings.showBuyingGroupContactDetails);

  const [buyingGroupDeliveryRecipients, setBuyingGroupDeliveryRecipients] =
    useState<string[]>(
      campaignAdvancedSettings.deliveryRecipients
        .filter((e) => e.type === CampaignDeliveryRecipientType.BuyingGroup)
        .map((e: { email: string }) => e.email)
    );

  const [hasAutomatedBuyingGroupelivery, setHasAutomatedBuyingGroupelivery] =
    useState(campaignAdvancedSettings.automatedBuyingGroupDeliveryEnabled);

  const [
    leadActivationSummariesDeliveryRecipients,
    setLeadActivationSummariesDeliveryRecipients,
  ] = useState<string[]>(
    campaignAdvancedSettings.deliveryRecipients
      .filter(
        (e) => e.type === CampaignDeliveryRecipientType.LeadActivationSummaries
      )
      .map((e: { email: string }) => e.email)
  );

  const [
    hasLeadActivationSummariesDeliveryEnabled,
    setHasLeadActivationSummariesDeliveryEnabled,
  ] = useState(campaignAdvancedSettings.leadActivationSummariesDeliveryEnabled);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CampaignActivationSettingsInputs>({
    mode: "onBlur",
    defaultValues: {
      pixelIds: campaignAdvancedSettings.pixelIds,
      leadDomainCap: campaignAdvancedSettings.leadDomainCap,
      averageDealSize: campaignAdvancedSettings.averageDealSize,
      averageSalesCycleMonths: campaignAdvancedSettings.averageSalesCycleMonths,
    },
  });

  const leadDomainCap = watch("leadDomainCap");
  const selectedPixelIds = watch("pixelIds");
  const averageDealSize = watch("averageDealSize");
  const averageSalesCycleMonths = watch("averageSalesCycleMonths");

  const listPixels =
    (companyId: number) =>
    async (
      params: FetchDataParamsWithToken<keyof CompanyPixel> & {
        pageSize?: number;
      }
    ) => {
      const { companyPixels, nextPageToken } = await listCompanyPixels(
        companyId,
        params
      );

      return {
        nextPageToken,
        results: companyPixels.map((pixel) => ({
          value: pixel.pixelId,
          label: pixel.displayName,
        })),
      };
    };

  const {
    items: beeswaxIds,
    addItem: addBeeswaxId,
    removeItem: removeBeeswaxId,
  } = useDynamicInput(campaignAdvancedSettings.beeswaxCampaignIds || []);

  const newCampaignAdvancedSettings: CampaignAdvancedSettings = useMemo(() => {
    return {
      automatedIntentDeliveryEnabled: hasAutomatedIntentDelivery,
      automatedBuyingGroupDeliveryEnabled: hasAutomatedBuyingGroupelivery,
      leadActivationSummariesDeliveryEnabled:
        hasLeadActivationSummariesDeliveryEnabled,
      deliveryRecipients: [
        ...intentDataDeliveryRecipients.map((e) => ({
          email: e,
          type: CampaignDeliveryRecipientType.Intent,
        })),
        ...buyingGroupDeliveryRecipients.map((e) => ({
          email: e,
          type: CampaignDeliveryRecipientType.BuyingGroup,
        })),
        ...leadActivationSummariesDeliveryRecipients.map((e) => ({
          email: e,
          type: CampaignDeliveryRecipientType.LeadActivationSummaries,
        })),
      ],
      beeswaxCampaignIds: beeswaxIds.map(Number),
      convertrCampaigns: [],
      pixelIds: selectedPixelIds,
      leadDomainCap,
      showBuyingGroupContactDetails,
      averageDealSize,
      averageSalesCycleMonths,
    };
  }, [
    hasAutomatedIntentDelivery,
    hasAutomatedBuyingGroupelivery,
    hasLeadActivationSummariesDeliveryEnabled,
    intentDataDeliveryRecipients,
    buyingGroupDeliveryRecipients,
    leadActivationSummariesDeliveryRecipients,
    beeswaxIds,
    selectedPixelIds,
    leadDomainCap,
    showBuyingGroupContactDetails,
    averageDealSize,
    averageSalesCycleMonths,
  ]);

  useImperativeHandle(ref, () => ({
    submit({ shouldExitOnSuccess }) {
      handleSubmit((values) => {
        const newSettings: WithCampaignId<
          Omit<CampaignAdvancedSettings, "convertrCampaigns">
        > = { id: id, ...newCampaignAdvancedSettings, ...values };

        onSubmit({
          newSettings,
          shouldExitOnSuccess,
        });
      })();
    },
  }));

  const haveChanged = useWatchActivationSettings(
    campaignAdvancedSettings,
    newCampaignAdvancedSettings
  );

  return (
    <>
      <NavigationBlockers isUnsavedChangesBlockerEnabled={haveChanged} />

      <SimpleGrid>
        <GridItem colSpan={{ md: 2 }}>
          <chakra.form method="POST" borderTopRadius={4}>
            <HStack spacing={2}>
              <Text
                fontSize="md"
                fontWeight="bold"
                color={componentColors.form.formLabelColor}
              >
                Content Syndication Programs
                <Tooltip
                  aria-label={"automated-intent-delivery-tooltip"}
                  placement={"top-end"}
                  label={
                    <>
                      These campaigns are pulled from the lead validator
                      platform, Convertr, and cannot be edited within this
                      screen. If changes are needed, please reach out to your
                      customer success manager.
                    </>
                  }
                >
                  <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
                </Tooltip>
              </Text>
            </HStack>

            <Text
              fontSize="sm"
              fontWeight="md"
              color={componentColors.form.formLabelColor}
              pb={2}
            >
              Below is a list of connected Convertr campaign IDs as well as an
              option to enter recipient emails for automated intent data
              delivery that correspond with their live content syndication
              programs.
            </Text>

            {isPopulatedArray(campaignAdvancedSettings.convertrCampaigns) ? (
              <UnorderedList fontSize="sm" pl={4}>
                {campaignAdvancedSettings.convertrCampaigns.map((i) => (
                  <ListItem key={i.convertrCampaignId}>
                    <Text
                      fontSize="sm"
                      fontWeight="md"
                      color={componentColors.form.formLabelColor}
                    >
                      {" "}
                      {i.displayName} ({i.convertrCampaignId}) — leads required:{" "}
                      <b>{i.leadsRequired}</b>
                    </Text>
                  </ListItem>
                ))}
              </UnorderedList>
            ) : (
              <Box>
                <Text fontSize="sm">Not available</Text>
              </Box>
            )}

            <FormControl
              isInvalid={!!errors?.leadDomainCap?.message}
              isRequired
              mt={4}
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Lead Domain Cap
              </FormLabel>
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                width="2xs"
                defaultValue={10}
                max={10}
                min={1}
                step={1}
              >
                <NumberInputField
                  {...register("leadDomainCap", { valueAsNumber: true })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <FormErrorMessage>
                {errors?.leadDomainCap?.message}
              </FormErrorMessage>
            </FormControl>

            <HasAccess to="campaign.intentDataDelivery">
              <Divider my={8} />
              <FormControl mt={8} as={GridItem} maxW="50%" pr={2}>
                <DeliveryRecipients
                  title="Automated Intent Delivery"
                  subtitle="Intent Delivery Recipients"
                  tooltip={
                    <>
                      This automated email delivery system is for any
                      customer/user that is interested in receiving our intent
                      intelligence for accounts that have garnered leads during
                      their campaign. Please toggle “on” and input an email
                      address below to begin the weekly cadence email service.
                    </>
                  }
                  recipients={intentDataDeliveryRecipients}
                  onRecipientsChange={setIntentDataDeliveryRecipients}
                  isEnabled={hasAutomatedIntentDelivery}
                  onIsEnabledChange={setHasAutomatedIntentDelivery}
                />
              </FormControl>
            </HasAccess>

            <HasAccess to="campaign.editShowBuyingGroupContactDetails">
              <Divider my={8} />
              <FormControl mt={8} as={GridItem} maxW="50%" pr={2}>
                <HStack spacing={2}>
                  <Text
                    p="0"
                    m="0"
                    fontSize="md"
                    fontWeight="bold"
                    color={componentColors.form.formLabelColor}
                  >
                    Show buying group contact details
                    <Tooltip
                      aria-label={"automated-intent-delivery-tooltip"}
                      placement={"top-end"}
                      label={
                        <>
                          When enabled, this setting will display contact
                          details of the buying group insights in the accounts
                          section.
                        </>
                      }
                    >
                      <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
                    </Tooltip>
                  </Text>

                  <Switch
                    isChecked={showBuyingGroupContactDetails}
                    onChange={(e) =>
                      setShowBuyingGroupContactDetails(e.currentTarget.checked)
                    }
                  />
                </HStack>
              </FormControl>
            </HasAccess>

            <Divider my={8} />

            <HasAccess to="campaign.moatReport">
              <FormControl
                isInvalid={!!errors?.averageDealSize?.message}
                mt={4}
              >
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color={componentColors.form.formLabelColor}
                >
                  Average Deal Size
                </FormLabel>
                <NumberInput
                  shadow="sm"
                  size="sm"
                  rounded="md"
                  width="2xs"
                  max={1_000_000_000_000}
                  min={1}
                  step={100}
                >
                  <NumberInputField
                    {...register("averageDealSize", { valueAsNumber: true })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>
                  {errors?.averageDealSize?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors?.averageSalesCycleMonths?.message}
                mt={4}
              >
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color={componentColors.form.formLabelColor}
                >
                  Averages Sales Cycle In Months
                </FormLabel>
                <NumberInput
                  shadow="sm"
                  size="sm"
                  rounded="md"
                  width="2xs"
                  max={12}
                  min={1}
                  step={1}
                >
                  <NumberInputField
                    {...register("averageSalesCycleMonths", {
                      valueAsNumber: true,
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>
                  {errors?.averageSalesCycleMonths?.message}
                </FormErrorMessage>
              </FormControl>

              <Divider my={8} />
            </HasAccess>

            <Flex
              mt={2}
              gap={4}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <FormControl as={GridItem} w="100%">
                <DynamicInput
                  label="Beeswax Campaign ID"
                  items={beeswaxIds}
                  addItem={addBeeswaxId}
                  removeItem={removeBeeswaxId}
                  formProps={{ mt: 0, w: "100%" }}
                  containerProps={{ pb: 0, w: "100%" }}
                />
              </FormControl>
              <FormControl
                as={GridItem}
                colSpan={[0, 3]}
                isInvalid={!!errors?.pixelIds}
                w="100%"
              >
                <Controller
                  name="pixelIds"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FilterAsyncTokenBased
                      label="Pixel ID"
                      defaultOptions={[]}
                      currentValue={value.map((pixel) => ({
                        value: pixel,
                        label: pixel,
                      }))}
                      onFilterValuesChange={(e) => {
                        onChange((e || []).map((s) => s.label));
                      }}
                      isMulti={true}
                      dataRequest={listPixels(companyId)}
                      showLabel={true}
                      params={{
                        ...defaultListPixelsParams,
                      }}
                    />
                  )}
                />
              </FormControl>
            </Flex>

            <HasAccess to="buyingGroup">
              <Divider my={8} />
              <FormControl as={GridItem} maxW="50%" pr={2}>
                <DeliveryRecipients
                  title="Automated Buying Group Contact Delivery"
                  subtitle="Buying Group Delivery Recipients"
                  tooltip={
                    <>
                      This automated email delivery system is for any
                      customer/user that is interested in receiving our intent
                      intelligence for accounts that have garnered leads during
                      their campaign. Please toggle “on” and input an email
                      address below to begin the weekly cadence email service.
                    </>
                  }
                  recipients={buyingGroupDeliveryRecipients}
                  onRecipientsChange={setBuyingGroupDeliveryRecipients}
                  isEnabled={hasAutomatedBuyingGroupelivery}
                  onIsEnabledChange={setHasAutomatedBuyingGroupelivery}
                />
              </FormControl>
            </HasAccess>

            <HasAccess to="campaign.leadActivationDeliverySetting">
              <Divider my={8} />
              <FormControl as={GridItem} maxW="50%" pr={2}>
                <DeliveryRecipients
                  title="Lead Activation Summaries Delivery"
                  subtitle="Lead Activation Summaries Recipients"
                  tooltip={
                    <>
                      This automated email delivery system is for any
                      customer/user that is interested in receiving our lead
                      activation summaries. Please toggle “on” and input an
                      email address below to begin the weekly cadence email
                      service.
                    </>
                  }
                  recipients={leadActivationSummariesDeliveryRecipients}
                  onRecipientsChange={
                    setLeadActivationSummariesDeliveryRecipients
                  }
                  isEnabled={hasLeadActivationSummariesDeliveryEnabled}
                  onIsEnabledChange={
                    setHasLeadActivationSummariesDeliveryEnabled
                  }
                />
              </FormControl>
            </HasAccess>
          </chakra.form>
        </GridItem>
      </SimpleGrid>
    </>
  );
});

export { ActivationSettingsForm };
