import { useQuery } from "@tanstack/react-query";
import { getTopVisitedUrls } from "../../Visualization.requests";
import { ColumnDef } from "@tanstack/react-table";
import { TopVisitedUrl } from "@intentsify/types";
import { Box, Flex, Link } from "@chakra-ui/react";
import { toNumberDisplayValue } from "@intentsify/utils";
import { Card, TableVirtualised } from "components";
import { ParentSize } from "@visx/responsive";
import { Props } from "../../../../WebAnalytics.types";

export const TopVisitedUrls = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  accountsIds,
  visitedPages,
}: Props) => {
  const { data: data, isFetching: isFetching } = useQuery({
    queryKey: [
      "webAnalyticsTopVisitedUrls",
      pixelId,
      accountView,
      campaignId,
      startDate,
      endDate,
      accountsIds,
      visitedPages,
    ],
    queryFn: () =>
      getTopVisitedUrls(pixelId, {
        accountView,
        campaignId,
        startDate,
        endDate,
        accountsIds,
        visitedPages,
      }),
  });

  const columns: ColumnDef<TopVisitedUrl>[] = [
    {
      header: "URL Visited",
      accessorKey: "url",
      cell: (info) => {
        return (
          <Box fontSize="xs">
            <Link href={String(info.getValue())} isExternal>
              {String(info.getValue())}
            </Link>
          </Box>
        );
      },
    },
    {
      header: "Page Visits",
      accessorKey: "pageVisits",
      cell: (info) => {
        return (
          <Box fontSize="xs">
            {toNumberDisplayValue(Number(info.getValue()))}
          </Box>
        );
      },
    },
    {
      header: "Accounts",
      accessorKey: "accounts",
      cell: (info) => {
        return (
          <Box fontSize="xs">
            {toNumberDisplayValue(Number(info.getValue()))}
          </Box>
        );
      },
    },
  ];
  return (
    <Card
      isLoading={isFetching}
      title="Top URLs Visited"
      hasData={!isFetching && Boolean(data?.data.length)}
    >
      <ParentSize>
        {(parent) => {
          const { width, height } = parent;

          return (
            <Flex w={width} h={height}>
              <TableVirtualised
                isSortDisabled={true}
                variant="corporateCompact"
                data={data?.data || []}
                columns={columns}
                containerProps={{
                  width: "100%",
                  maxHeight: "400",
                }}
              />
            </Flex>
          );
        }}
      </ParentSize>
    </Card>
  );
};
