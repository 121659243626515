import { CompanyPixel } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";
import { ListCompanyPixelsRO } from "@intentsify/dto";

export const listPaginatedPageTracking = async (
  companyId: number,
  params: FetchDataParamsWithToken<keyof CompanyPixel>
): Promise<ListCompanyPixelsRO> => {
  const { data } = await apiService.get<ListCompanyPixelsRO>(
    Endpoints.Companies.Get.ListPixels(companyId),
    { params }
  );

  return data;
};
