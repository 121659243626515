import { useCallback } from "react";
import { useLocation } from "react-router";
import { ChartDownloadedProperties, ampli } from "./amplitude";

export const useTrackChartDownloaded = () => {
  const location = useLocation();

  return useCallback(
    ({ title, campaignId }: Omit<ChartDownloadedProperties, "pagePath">) => {
      ampli.chartDownloaded({
        title,
        pagePath: location.pathname,
        campaignId,
      });
    },
    [location.pathname]
  );
};
