import { Flex } from "@chakra-ui/react";
import {
  CustomersByRevenueSize,
  CustomersByEmployeeSize,
  CustomersByGeography,
  WebsiteVisits,
  TopVisitedUrls,
  SiteEngagementLift,
  Statistics,
} from "./components";
import { Props } from "../../WebAnalytics.types";

const Visualization = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  accountsIds,
  visitedPages,
}: Props) => {
  return (
    <Flex wrap="wrap">
      <Flex width="100%">
        <Statistics
          pixelId={pixelId}
          accountView={accountView}
          campaignId={campaignId}
          startDate={startDate}
          endDate={endDate}
          accountsIds={accountsIds}
          visitedPages={visitedPages}
        />
      </Flex>
      <Flex width="100%" wrap="wrap" gap={4}>
        <Flex width="100%">
          <WebsiteVisits
            pixelId={pixelId}
            accountView={accountView}
            campaignId={campaignId}
            startDate={startDate}
            endDate={endDate}
            accountsIds={accountsIds}
            visitedPages={visitedPages}
          />
        </Flex>
        {campaignId && (
          <Flex width="100%">
            <SiteEngagementLift campaignId={campaignId} />
          </Flex>
        )}
        <Flex width="100%" gap={4} minH="350px">
          <TopVisitedUrls
            pixelId={pixelId}
            accountView={accountView}
            campaignId={campaignId}
            startDate={startDate}
            endDate={endDate}
            accountsIds={accountsIds}
            visitedPages={visitedPages}
          />
          <CustomersByGeography
            pixelId={pixelId}
            accountView={accountView}
            campaignId={campaignId}
            startDate={startDate}
            endDate={endDate}
            accountsIds={accountsIds}
            visitedPages={visitedPages}
          />
        </Flex>
        <Flex width="100%" gap={4}>
          <CustomersByEmployeeSize
            pixelId={pixelId}
            accountView={accountView}
            campaignId={campaignId}
            startDate={startDate}
            endDate={endDate}
            accountsIds={accountsIds}
            visitedPages={visitedPages}
          />
          <CustomersByRevenueSize
            pixelId={pixelId}
            accountView={accountView}
            campaignId={campaignId}
            startDate={startDate}
            endDate={endDate}
            accountsIds={accountsIds}
            visitedPages={visitedPages}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Visualization };
