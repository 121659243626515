import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Link, Text } from "@chakra-ui/react";
import { Modal } from "components";

import { ReactComponent as AutodiscoverySvg } from "./assets/ai.svg";

const LinkedInLink = ({ linkedInUrl }: { linkedInUrl: string }) => {
  return (
    <Link href={linkedInUrl} isExternal>
      <ExternalLinkIcon mx="2px" /> {linkedInUrl}
    </Link>
  );
};

const LeadActivationModal = ({
  isOpen,
  onClose,
  location,
  jobTitle,
  leadActivationSummary,
}: {
  isOpen: boolean;
  onClose: () => void;
  location: string[];
  jobTitle: string;
  leadActivationSummary: string;
}) => {
  const linkRegex = /(https?:\/\/)([^ ]+)/g;
  const linkedInUrl = leadActivationSummary.match(linkRegex)?.[0];

  const summaryParts = linkedInUrl
    ? leadActivationSummary.split(linkedInUrl)
    : undefined;

  const parts = linkedInUrl &&
    summaryParts && [
      summaryParts[0],
      <LinkedInLink key={linkedInUrl} linkedInUrl={linkedInUrl} />,
      summaryParts[1],
    ];

  return (
    <Modal
      size={"2xl"}
      isOpen={isOpen}
      title={jobTitle}
      subtitle={location.join(", ")}
      onClose={onClose}
      body={
        <Box mb={4} style={{ whiteSpace: "pre-line" }}>
          {parts || leadActivationSummary}

          <Divider mt={4} />

          <Flex mt={4} alignItems="center">
            <AutodiscoverySvg width="30px" height="30px" />
            <Text fontSize="sm" fontStyle="oblique" ml={4}>
              This summary was generated using Intentsify AI.
            </Text>
          </Flex>
        </Box>
      }
    />
  );
};

export { LeadActivationModal };
