import { Route, Routes } from "react-router";
import {
  PageTracking,
  PageTrackingScreenDefinition,
  WebAnalytics,
  WebAnalyticsScreenDefinition,
  EditPixelScreenDefinition,
  EditPixel,
  CreatePixelScreenDefinition,
  CreatePixel,
  DeliveryRecipientScreenDefinition,
  DeliveryRecipient,
} from "screens";

const WebsiteVisitorTrackingRouter = () => {
  return (
    <Routes>
      {WebAnalyticsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<WebAnalytics />} />
      ))}
      {PageTrackingScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<PageTracking />} />
      ))}
      {CreatePixelScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CreatePixel />} />
      ))}
      {EditPixelScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<EditPixel />} />
      ))}
      {DeliveryRecipientScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<DeliveryRecipient />} />
      ))}
    </Routes>
  );
};

export { WebsiteVisitorTrackingRouter };
